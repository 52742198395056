import React from "react";
import api from "../../../api";
import "../../Grid/grid.css";

import { Segment, Table, Pagination } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";

import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../ChatSetup/components/Modal";
import AdditionalCaptured from "./AdditionalCaptured";

import ViewDetails from "./ViewDetails";

import ChatStatus from "./ChatStatus";
import { CSVLink, CSVDownload } from "react-csv";
import Notes from "./Notes";
import { throwStatement } from "@babel/types";
import DialogBoxHipaaPin from "../DialogBoxHipaaPin";
import CustomErrorField from "../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { parse } from "query-string";
import CustomModel from "./CustomModel";
import moment from 'moment';

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
    checkbox: true,
  },
  {
    title: "Tags",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "Date",
    width: "3",
    sort: "date_added",
  },
  {
    title: "Job Description",
    width: "3",
    sort: "job_description",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "Chat Id",
    width: "1",
    sort: "",
  },
  {
    title: "Transcript Status",
    width: "3",
    sort: "chat_status",
  },
  {
    title: "Name",
    width: "2",
    sort: "user_name",
  },
  {
    title: "Zip Code",
    width: "1",
    sort: "zip_code",
  },
  {
    title: "Phone Number",
    width: "1",
    sort: "phone_number",
  },
  {
    title: "Email",
    width: "1",
    sort: "chat_email",
  },
  // {
  //   title: "Lender",
  //   width: "1",
  //   sort: "lender",
  // },
  {
    title: "Site Url",
    width: "1",
    sort: "host",
  },
  {
    title: "Utm Source",
    width: "1",
    sort: "utm_source",
  },
  {
    title: "Utm Medium",
    width: "1",
    sort: "utm_medium",
  },
  {
    title: "Utm Campaign",
    width: "1",
    sort: "utm_campaign",
  },
  {
    title: "Utm Content",
    width: "1",
    sort: "utm_content",
  },
  
  {
    title: "Location",
    width: "1",
    sort: "location_name",
  },
  {
    title: "Additional Captured Fields",
    width: "1",
    sort: "",
  },
  {
    title: "Team Notes",
    width: "1",
    sort: "",
  },
  ,
  {
    title: "Ip Address",
    width: "1",
    sort: "",
  },
  {
    title: "Ga Id",
    width: "1",
    sort: "",
  },
  {
		title: "Parsed Data",
		width: "2",
		sort: "",
	},
  {
    title: "Notifications",
    width: "2",
    sort: "",
}
  
    
  
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "chatstatus",
  "chatfrequency",
  "time_zone",
  "startdaterange",
  "enddaterange",
];
const twl_errs={13224:"Twilio does not support calling this number or the number is invalid",13223:"Twilio does not support calling this number or the number is invalid",13226:"Invalid country code",30005:"Unknown destination handset",30006:"Landline or unreachable carrier",30001:"Queue overflow",30003:"Unreachable destination handset",30014:"To attributes are Invalid"};
export default class DatatableList extends React.Component {
   #oneMonthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
    #today =moment().format('MM/DD/YYYY');
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "auto_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      chatstatus: "all",
      chatfrequency: "daterange",
			startdaterange: this.#oneMonthAgo,
			enddaterange: this.#today,
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      modalfooter: "",
      modalBody: "",
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      auto_id: "",
      modalTranscript: "",
      isgpt:false,
      csvdata: [],
      hipaaValidateObject: {},
      modalstatusHIPAA:false,
      user: props.user,
      reportcommnet:'',
      reporterror:false,
      reportstatus:'',
      modalBtn:true,
      oldTxt:'',
      custom_search:[],
      custom_searchdata:[],
      parsed_data:{},
      openCustomModal:false,
      curid:"",
      existdata:[],
      parsed_config:[],
      is_required:0,
      date_required:0,
			user_timezone:'',
      job_description:'',
      use_address:0,
      parsebtnName:"parsebtn",
      requestedFiles:[],
			alert_msg:''
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }
  
  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    console.log("statattata", this.state);
    if (filter == "chatfrequency") {
      this.setState({
        startdaterange: "",
        enddaterange: "",
      });
    }
    //if (filter !== this.state.q) {
    this.loadData({ [filter]: val, _page: 1 });
    // }
  };

  onSubmitFilterDateReange = (parmas) => {
    this.loadData({
      chatfrequency: parmas["chatfrequency"],
      startdaterange: parmas["startdaterange"],
      enddaterange: parmas["enddaterange"],
      _page: 1,
    });
  };
  
  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
    });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.chatlist.getLists(query).then((data) => {
        try {
          let datatableRowstemp;
          this.setState({ lists: data.data.list });

          if (data.data.totalrecord) {
            console.log(data.data.list);
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                key={index}
                list={list}
                viewAdditionalCaptured={this.viewAdditionalCaptured}
                viewDetails={this.viewDetails}
                viewNotes={this.viewNotes}
                openHipaaRow = {this.openHipaaRow}
                viewPaerseData={this.viewPaerseData}
                notificationDetails={this.notificationDetails}

              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
            requestedFiles:data.data?.requested_file ? data.data?.requested_file:[],
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  viewPaerseData = (data,id,isgpt) => {
    console.log("custom cap",data);
    this.setState({loading:true,parsebtnName:"parsebtn"});
    //this.getDetails(id,isgpt);
		if (data) {
			try {
				data = JSON.parse(data);
			} catch (error) {
				data = {}
			}
			data = data == null ? {} : data;
      let recordlist = this.state.lists.findIndex(
        (list) => list.auto_id === id
      );
      this.setState({loading:true}); 
      api.chatlist.viewDetails({ auto_id: id,isgpt }).then((data1) => {
        try {

          this.setState({
            loading: false,
            modalstatus: true,
            modaltitle: "",
            modalfooter: "",
            loading: false,
            custom_search:data1.data.custom_search,
            custom_searchdata:data1.data.search_data,
            parsed_data:data1.data.parsed_data,
            curid:id,
            parsed_config:data1.data.parsedconfig,
            existdata:data1.data.exist_data,
            date_required:data1.data.date_required,
					  user_timezone:data1.data.timezone, 
            is_required:data1.data.is_required,
            use_address:data1.data.use_address,
            job_description: this.state.lists[recordlist].job_description,
            parsebtnName:"parsebtn",
            modalbody: (
              Object.keys(data).length  ? 
              <Table id="additional_captured">
                <Table.Body>
                  {Object.keys(data).map(function(key,index){
                    return (
                      <Table.Row style={{wordBreak:'break-all'}}>
                        <Table.Cell>{key}</Table.Cell>
                        <Table.Cell>{(typeof data[key] == 'string') ? data[key] : JSON.stringify(data[key])}</Table.Cell>
                      </Table.Row>
                      )
                    }) 
                  }
                </Table.Body>
              </Table> : <>
              <h2>No data available.</h2>
              </>
            ),
            modalTranscript: false
          },() => console.log(this.state.modalbody));

        } catch (err) {}
      });
       
      

			
			return false;
		}else{
			this.setState({
				loading: false,
				modalstatus: true,
				modaltitle: "",
				modalfooter: "",
				modalbody: (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<span style={{ fontWeight: 600, fontSize: 14, color: 'red' }}>No data available.</span>
					</div>

				),
				modalTranscript: false
			});
			return false;
		}
	}

  openpushdata_model(){

  }
  

  viewDetails = (auto_id,isgpt) => {
    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    api.chatlist.viewDetails({ auto_id: auto_id,isgpt }).then((data) => {
      try {
        this.setState({

          loading: false,
          modalstatus: true,
          modaltitle: "",
          modalfooter: "",
          auto_id:auto_id,
          isgpt:isgpt,
          custom_search:data.data.custom_search,
          custom_searchdata:data.data.search_data,
          parsed_data:data.data.parsed_data,
          curid:auto_id,
          existdata:data.data.exist_data,
          parsed_config:data.data.parsedconfig,
          is_required:data.data.is_required,
          date_required:data.data.date_required,
					user_timezone:data.data.timezone,
          use_address:data.data.use_address,   
          job_description: this.state.lists[recordlist].job_description,
          modalbody: (
            <ViewDetails
              detaildata={data.data.data}
              custom_search={data.data.custom_search}
              auto_id={auto_id}
              isgpt={isgpt}
              updatenote={this.updateNote}
              openCustomPopup={this.openCustomPopup}
            ></ViewDetails>
          ),
          modalTranscript: (
            <ChatStatus
              chatRef="chat_log"
              auto_id={auto_id}
              selected_chat={data.data.chatstatus}
              list={[
                "Change Status",
                {
                    label: "Customer Contacted",
                    submenu: [
                        { label: "Customer Contacted", value: 1 },
                        { label: "Contacted attempted-left message", value: 4 },
                        { label: "Contacted attempted-unable to leave message", value: 5},
                    ],
                },
                "Customer Cancelled",
                "Customer Serviced",
            ]}
            />
          ),
        });
      } catch (err) {}
    });
  };
  getDetails = (auto_id,isgpt) => {
    this.setState({loading:true}); 
    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    api.chatlist.viewDetails({ auto_id: auto_id,isgpt }).then((data) => {
      try {
        this.setState({
          loading: false,
          custom_search:data.data.custom_search,
          custom_searchdata:data.data.search_data,
          parsed_data:data.data.parsed_data,
          curid:auto_id,
          existdata:data.data.exist_data,
        });
      } catch (err) {}
    });
  };

  notificationDetails=(id)=>{
          this.setState({loading:true});
          let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
          let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
          let time_zone = clientTimeZone;
          api.transcriptchatlist.viewChatVoiceLog({ auto_id: id,timezone:time_zone}).then((data1) => {
              try {
                  console.log(data1.data);
                  let stateobj={
                      loading: false,
                      modalstatus: true,
                      modaltitle: "",
                      modalfooter: "",
                      modalbody: (
                          Object.keys(data1?.data).length  ? 
                          <div className="row tracklogtable" style={{height:'42vh',overflow:'hidden auto',width:'565px'}}><Table id="additional_captured" style={{maxHeight:'400px',overflowY:'Auto'}}>
                                  <Table.Header>
                                      <Table.Row>
                                          <Table.HeaderCell>Email or Number</Table.HeaderCell>
                                          <Table.HeaderCell>Type</Table.HeaderCell>
                                          <Table.HeaderCell>Status</Table.HeaderCell>
                                          <Table.HeaderCell>Date/Time</Table.HeaderCell>
                                      </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                  {data1?.data.map(function(arr,index){
                                          let log=typeof arr?.log!='undefined' && typeof arr?.log=='string' ? JSON.parse(arr?.log): arr?.log;
                                          return (
                                              <Table.Row key={index}>
                                                  <Table.Cell>{arr?.email_number}</Table.Cell>
                                                  <Table.Cell>{arr?.type=='Sms'? 'SMS':arr?.type}</Table.Cell>
                                                  {arr?.status!=="Undelivered" && arr?.status!=="Failed" ? <Table.Cell>{arr?.status}</Table.Cell>:
                                                   <Table.Cell>{arr?.status}&nbsp;<br/>{isNaN(log?.ErrorCode) ?
                                                      <span><strong>{log?.ErrorCode}</strong></span>:
                                                      typeof twl_errs[log?.ErrorCode]!=='undefined' ? <b>{twl_errs[log?.ErrorCode]}</b>:<span>Error:<a  rel="noreferrer" title="Check error message" target="_blank" href={`https://www.twilio.com/docs/api/errors/${log?.ErrorCode}`}>{typeof twl_errs[log?.ErrorCode]!=='undefined'? twl_errs[log?.ErrorCode]:log?.ErrorCode}</a></span>}</Table.Cell>}
                                                  <Table.Cell>{arr?.updatedat}</Table.Cell>
                                              </Table.Row>
                                          )
                                      })
                                      }
                                  </Table.Body>
                              </Table></div> : <>
                                  <h2>No data available.</h2>
                              </>
                      ),
                      modalTranscript: false
                  };
  
                  this.setState(stateobj);
                  return false;
  
              } catch (err) {
  
              }
          });
  
      }
      changeTableRowBackground = (auto_id) => {
        const allTableRows = document.querySelectorAll('.table-row-ai');
        allTableRows.forEach((row) => {
            row.classList.remove('active');
        });
        const activeRow = document.querySelector(`.tr__${auto_id}`);
        activeRow.classList.add('active');
    }
  openCustomPopup=(search)=>{
    this.setState({openCustomModal:true,modalstatus:false});
  }

  closeCustomPopup=()=>{
    this.setState({openCustomModal:false,parsed_data:[],custom_search:[],custom_searchdata:[],curid:"",existdata:[]});
  }


  updateNote = (auto_id, value) => {
    console.log("hii----->>>>>>>>>", auto_id + "----" + value);

    let datalist = this.state.lists;

    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    datalist[recordlist]["team_notes"] = value;
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}
          viewPaerseData={this.viewPaerseData}
          notificationDetails={this.notificationDetails}
        />
      )),
      lists: datalist,
    });
  };

  


  viewAdditionalCaptured = (auto_id) => {
    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    let modaldata = this.state.lists[recordlist]["additionl_captured"];

    this.setState({
      loading: false,
      modalstatus: true,
      modaltitle: "Additional Captured Fields",
      modalfooter: "",
      modalbody: (
        <AdditionalCaptured
          additional_captured={modaldata}
        ></AdditionalCaptured>
      ),
      modalTranscript: false,
    });
  };

  viewNotes = (auto_id) => {
    try {
      let notes_data = "";
      this.setState({
        loading: false,
        modalstatus: true,
        modaltitle: "Team Notes",
        modalfooter: "",
        modalbody: (
          <Notes
            isgpt={this.state.isgpt}
            auto_id={auto_id}
            commentform="false"
            tableref="chat_log"
            updatenote={this.updateNote}
          ></Notes>
        ),
        modalTranscript: false,
      });
    } catch (err) {}
  };

  modalFooter = (type) => {};

  modalClose = () => {
    this.setState({
      modalstatus: false,
      parse_data:[],
      custom_search:[],
      custom_searchdata:[],
    });
  };

  modalFooter = (id, type) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDelete(this.state.modaldata.id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  };

  modalBody = () => {
    if (this.state.modalType == "job_description") {
      this.getJobDescriptionView(this.state.auto_id);
    }

    if (this.state.modalType == "additional_captured") {
      this.getAdditionalCapturedView(this.state.auto_id);
    }

    if (this.state.modalType == "notes") {
      this.getTeamNotesView(this.state.auto_id);
    }
  };

  getJobDescriptionView = (id) => {};
  getAdditionalCapturedView = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.auto_id === id);
    console.log(recordlist);
    let modaldata = this.state.lists[recordlist]["additionl_captured"];
    console.log(modaldata);
    Object.keys(modaldata).map(function () {});
  };
  getTeamNotesView = (id) => {};

  downloadCSV = (event, done) => {
    let duration=0;
    let params = {};
    params["chatstatus"] = this.state.chatstatus;
    params["chatfrequency"] = this.state.chatfrequency;
    params["strsearch"] = this.state.q;
    params["startdaterange"] = this.state.startdaterange;
    params["enddaterange"] = this.state.enddaterange;

    let list = this.state.lists;
    let selected_arr = [];
    list.filter(function (key) {
      if (key["checked"] == true) {
        selected_arr.push(key["auto_id"]);
      }
    });

      var end = moment(this.state.enddaterange); 
      var now = moment(this.state.startdaterange);
      duration = end.diff(now,'days');
      list.filter(function (key) {
        if (key["checked"] == true) {
          selected_arr.push(key["auto_id"]);
        }
      });
      this.setState({ loading: true });

    params["selectallcheckbox"] = selected_arr.length > 0 ? "false" : "true";
    params["chatidscheck"] = JSON.stringify(selected_arr);
    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    let params1 = {...params, user_id: this.props.user.user_id,chatBotId: this.props.user.chatBot_id,timezone: time_zone};
    params["timezone"]=time_zone
		if((this.state.chatfrequency==="daterange" && duration>90) || this.state.chatfrequency=='pastyear' ){
      api.chatlist.getChatExport(params1).then((data) => {
            if(data.status){
             // let ti = setInterval(this.checkRequestedFiles, 10000);
              this.setState({loading: false,alert_msg:'The CSV file will be available for download soon. Please refresh the page after a few minutes.',requestedFiles:[]});
              setTimeout(() => {
                this.setState({alert_msg:''});
              }, 5000);
            }
            
          });
          
        }else{
          api.chatlist.getCSVData(params).then((data) => {
            try {
              this.setState(
                {
                  csvdata: data.data.data,
                },
                
                ()=> {
                  if(data.data.status){
                    setTimeout(
                      function () {
                        document.querySelector("#chatcsv").click();
                        this.setState({ loading: false });
                      }.bind(this),
                      1000
                    );
                  }else{
                    this.setState({ loading: false });
                  }
                  
                }
              );
            } catch (err) {}
          });
   }
  };

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}
          viewPaerseData={this.viewPaerseData}
          notificationDetails={this.notificationDetails}

        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}
          viewPaerseData={this.viewPaerseData}
          notificationDetails={this.notificationDetails}

        />
      )),
      lists: datalist,
    });

    //  let array=this.state.checkedboxes;
    // const index = this.state.checkedboxes.indexOf(auto_id);
    // if (index > -1) {
    //   array.splice(index, 1);
    // }else{
    //   array.push(auto_id)
    // }

    // this.setState({
    //   checkedboxes:array
    // });
  };

  openHipaaRow = (auto_id) => {
    this.setState({loading:true})
    api.manageUser.checkHipaaAccount({user_id:this.props.user.user_id,email:this.props.user.email}).then((data) => {
      try {
        if(data.data.data.subaccount_id=="found"){
          var type = "validate";
        } else {
          var type = "register";
        }
        this.setState({
          loading:false,
          modalstatusHIPAA: true,
          modaltitle:
            "To view sensitive chat information, please enter your authorize email and PIN",
          hipaaValidateObject: {
            userId: this.props.user.user_id,
            userType: "User",
            via: "user",
            auto_id: auto_id,
            pin_email: this.props.user.email,
            refId: auto_id,
            chat_section:"chatlist",
            type:type
            
          },
        });
      } catch (err) {}
    });
    
    
  };

  openHipaaAfterRegister = auto_id =>{
    this.setState({
      loading:false,
      modalstatusHIPAA: true,
      modaltitle:
        "To view sensitive chat information, please enter your authorize email and PIN",
      hipaaValidateObject: {
        userId: this.props.user.user_id,
        userType: "User",
        via: "user",
        auto_id: auto_id,
        pin_email: this.props.user.email,
        refId: auto_id,
        chat_section:"chatlist",
        type:"validate"
        
      },
    });
  }

  onValidHipaaSubmit = (auto_id)=>{
    let datalist = this.state.lists;

    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );


    datalist[recordlist]["hippaOn"] = "none";
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}
          viewPaerseData={this.viewPaerseData}
          notificationDetails={this.notificationDetails}
        />
      )),
      lists: datalist,
    });
    this.setState({
      chat: this.state.chat,
      chatLeftList:this.state.chatLeftList,
      modalstatusHIPAA: false,
      name: this.state.name,
    });
  }

  hipaaModalClose = e =>{
    this.setState({
      modalstatusHIPAA: false,
    });
  }
  handleChangereport=(e)=>{
    let btn=true;
    let newtxt=e.target.value.trim();
    if(newtxt!==this.state.oldTxt){
       btn=false;
    }
    if(newtxt.length===0){
			btn=true;
		}
    this.setState({reportcommnet:newtxt,reporterror:false,modalBtn:btn},()=>{ this.updatemodelBody();});
  }
  
  saveReport=(e)=>{
    e.preventDefault();
    let note=this.state.reportcommnet.trim();
    this.setState({loading:true,modalBtn:true},()=>{
      this.updatemodelBody();
    });
    if(note.length!=0){
      api.chatlist.savechatReport({id:this.state.auto_id,report:note}).then((data) => {
        try {
          
            if(data.status){
              this.setState({reportcommnet:'',auto_id:'',reporterror:false, modaltitle: "Report Chat For Training",reportstatus:data.message,loading:false,oldTxt:note});
              setTimeout(()=>{
                    this.setState({modalstatus:false,reportstatus:""})
              },3000);
            }
            this.updatemodelBody();
        }catch (err){

        }

    });
  }else{
    this.setState({reporterror:true,loading:false})
    this.updatemodelBody();
  }
  
}
  updatemodelBody=()=>{
    let body=(<form id="notesForm1" method="post">
          <Message
                  color="teal"
                  style={{
                      display: this.state.reportstatus?.length ? "block" : "none",
                      marginTop: 30,
                  }}
              >
                  {this.state.reportstatus}
              </Message>
              Please provide additional details why this chat needs training?
              <div className="form-group">
       
                  <textarea rows="5" onChange={(e)=>this.handleChangereport(e)} name="reportcommnet" id="notes"placeholder="Write notes here ..." defaultValue={this.state.reportcommnet}  className={
                                            this.state.reporterror 
                                                ? "form-control is-invalid"
                                                : "form-control"}></textarea>
                  {this.state.reporterror &&  <CustomErrorField
                                                                message="This field is required"
                                                                id="help-error"
                                                                className="error invalid-feedback"
                                                            />
                                                        }
              </div>
            <div className="form-group">
                <button type="button" disabled={this.state.modalBtn} onClick={(e)=>{ this.setState({modalBtn:true},()=>{
            this.updatemodelBody();
          });
          this.saveReport(e)}} 
          style={{width:'100%' ,
    marginTop:'20px'}} className="btn linear-gradient yr-submitbtn btn-md sbold btn-block">Submit</button>
            </div>
           
      </form>);
    this.setState({modalbody:body});
    return body;
  }

  showtrainlogModal=(id)=>{
      this.setState({loading:true});
    api.chatlist.getTrainingLog("id="+id).then((data) => {
      try {
        let msg=data.data;
        this.setState({reportcommnet:msg,oldTxt:msg});
        let notes_data = "";
        let body=this.updatemodelBody();
        this.setState({
          loading: false,
          modalstatus: true,
          modaltitle: "Report Chat For Training",
          modalfooter: "",
          modalbody:body ,
          reporterror:false,
          modalTranscript: false,
          openCustomModal:false
          
        });
       
      } catch (err) {}
  });
  }
  

  render() {
    return (
      <div className="completedchats">
      {this.state.alert_msg && <div className="alert alert-success">{this.state.alert_msg}</div>}
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          csvdata={this.state.csvdata}
          dowloadcsv={this.downloadCSV}
          requestedFiles={this.state.requestedFiles}
          showDarerangeErrorMessage={this.showDarerangeErrorMessage}
        />
        <Segment style={{ width:"100%", maxWidth:"calc(96vw)"  }} >
          {this.state.loading && <div className="ui loading form"></div>}

          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
        <DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
        <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />
        <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
          isgpt={this.state.isgpt}
          auto_id={this.state.auto_id}
          showtrainlogModal={this.showtrainlogModal}
          custom_search={typeof this.state.custom_search!="undefined" ? this.state.custom_search : []}
          openCustomPopup={this.openCustomPopup}
          search_data={typeof this.state.custom_searchdata!="undefined" ? this.state.custom_searchdata : []}
          parsed_data={typeof  this.state.parsed_data!="undefined" ? this.state.parsed_data:{}} 
          parsebtnName={this.state.parsebtnName ?? ""}
        />

{(typeof this.state.custom_search!="undefined" && typeof this.state.custom_searchdata!="undefined" && typeof this.state.parsed_data!="undefined" )  && <CustomModel
          show={this.state.openCustomModal}
          footer={this.state.modalfooter}
          modalClose={this.closeCustomPopup}
          custom_search={this.state.custom_search}
          custom_searchdata={this.state.custom_searchdata}
          parsed_data={this.state.parsed_data}
          exist_data={this.state.existdata}
          title="Push Data Manually"
          curid={this.state.curid}
          module="chat"
          parsed_config={this.state.parsed_config}
          is_required={this.state.is_required}
          date_required={this.state.date_required}
				 time_zone={this.state.user_timezone}
         job_description={this.state.job_description}
         use_address={this.state.use_address}
        />}
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename="chat.csv"
          className="hidden"
          target="_blank"
        />
         <DialogBoxHipaaPin
          email={this.props.user.email}
          obj={this.state}
          modalClose={this.hipaaModalClose}
          onValidHipaaSubmit={this.onValidHipaaSubmit}
          openHipaaAfterRegister={this.openHipaaAfterRegister}
        />
      </div>
    );
  }
}
