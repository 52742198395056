import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function createMarkup(html) {
	return { __html: html };
}

const CUSTOMER_STATUS = ['Customer Contacted', 'Customer Cancelled', 'Customer Serviced'];
export const DatatableRow = props => {
	let parseData = props?.list?.custom_capture ? props?.list?.custom_capture : {};
	return (
	<Table.Row className={`table-row-ai tr__${props.list.auto_id}`}>
		<Table.Cell>

			<label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false} auto_id={props.list.auto_id} onChange={() => props.channgeEvent(props.list.auto_id)}></input>&nbsp;<span></span></label>

		</Table.Cell>
		<Table.Cell style={{ whiteSpace: "nowrap", width: "200px" }}>
			<div style={{ width: 170 }}>{props.list.created_at}</div>
		</Table.Cell>
		<Table.Cell>
			<div style={{ width: 35 }}>{props.list.call_from ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.call_from}</span></span> : ''}
			</div>
		</Table.Cell>
		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span> : <a className="cursorpointer des-link" onClick={() => props.viewDetails(props.list.auto_id)}>View Details</a>}
			</div>
		</Table.Cell>
		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span> : <a className="cursorpointer des-link" onClick={() => props.viewRecording(props.list.call_recording, props.list.auto_id)} style={{display: 'flex', alignItems: 'center', columnGap: 8}}><span>Listen</span> <i className='fa fa-volume-up'></i></a>}
			</div>
		</Table.Cell>
		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span> : <a className="cursorpointer des-link" onClick={() => props.viewSummary(props.list.auto_id)}>View Details</a>}
			</div>
		</Table.Cell>

		<Table.Cell id={"ai_chatstatus_" + props.list.auto_id} style={{width: "150px"}}>{props.list.chat_status?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.chat_status:""}</Table.Cell>

		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span> : <a className="cursorpointer des-link" onClick={() => props.viewNoteshtml(props.list.auto_id)}>View Notes</a>}
			</div>
		</Table.Cell>
    
		{/* <Table.Cell>
			<div style={{ width: 100 }}>{props.list.forwarded ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.forwarded}</span></span> : ''}
			</div>
		</Table.Cell> */}
		<Table.Cell>
			<div style={{ width: 100 }}>{props.list.ai_call_duration ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.ai_call_duration}</span></span> : ''}
			</div>
		</Table.Cell>
		{/* <Table.Cell>
			<div style={{ width: 100 }}>{props.list.human_call_duration ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.human_call_duration}</span></span> : ''}
			</div>
		</Table.Cell> */}
		<Table.Cell>
			<div style={{ width: 100 }}>{props.list.call_abandoned ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.call_abandoned}</span></span> : ''}
			</div>
		</Table.Cell>
		{/* <Table.Cell>
			<div style={{ width: 100 }}>{props.list.name ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.name}</span></span> : ''}
			</div>
		</Table.Cell>
		<Table.Cell>
			<div style={{ width: 100 }}>{props.list.zipcode ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.zipcode}</span></span> : ''}
			</div>
		</Table.Cell>
		<Table.Cell>
			<div style={{ width: 100 }}>{props.list.zipcode ? (props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> : <span ><span>{props.list.email}</span></span> : ''}
			</div>
		</Table.Cell> */}
		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> :<span style={{display: 'flex', flexDirection: 'column', rowGap: 8}}><a className="cursorpointer kt-badge  kt-badge--info kt-badge--inline kt-badge--pill" style={{color: 'white', fontWeight: 500}} onClick={() => props.viewPaerseData(parseData,props.list.auto_id)}>View Parsed Data</a> </span>}
			</div>
		</Table.Cell>
		<Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> :<span style={{display: 'flex', flexDirection: 'column', rowGap: 8}}><a className="cursorpointer kt-badge  kt-badge--info kt-badge--inline kt-badge--pill" style={{color: 'white', fontWeight: 500}} onClick={() => props.notificationDetails(props.list.auto_id)}>View Details</a> </span>}
			</div>
		</Table.Cell>
	</Table.Row>
)};

DatatableRow.propTypes = {
	list: PropTypes.object.isRequired,
};
