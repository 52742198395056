import React from "react";
import "./LiveChat.css";
import { connect } from "react-redux";
import { Accordion, Icon, Checkbox, Form, Radio, TextArea, Input, Flag, Message, Dimmer, Loader, Segment, Popup } from 'semantic-ui-react';
import LeftSideAccordion from "./components/LeftSideAccordion";
import {
	toggleNavigation,
	setPageTitle,
	setMessage,
	toggleBreadcrum,
} from "../../../store/Page";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";
import ScrollToBottom from "react-scroll-to-bottom";
import axios from "../../../config/axios";
import config from "../../../config/index";
import api from "../../../api";
import DialogBoxHipaaPin from "../DialogBoxHipaaPin";
import LiveChatBoxFooter from "./components/LiveChatBoxFooter";
import DialogBox from "./Modal/DialogBox";
import OpenQuickText from "./components/OpenQuickText";
import QuickSelect from "./components/QuickSelect";
import NotificationMemberList from "./components/NotificationMemberList";
import DialogBoxConfirmation from "./components/DialogBoxConfirmation";
import ConfirmDialog from '../../Modal/ConfirmDialog'
import DialogBoxSoundNotificationSetting from "./components/DialogBoxSoundNotificationSetting";
import LiveChatTab from "./LiveChatTab";
import Button from '../../UI/button/Button';
import EngageChat from './smschat/engagechat/EngageChat';
import Modal from '../ChatSetup/components/Modal';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import InputNumber from './InputNumber';
import classes from './LiveChat.module.css';
import UrlShortner from './UrlShortner';
import utilFunc from "../../../util_funs";
import ChatWithNoReply from "./smschat/noreplychat/ChatWithNoReply";
import ArchiveChat from "./smschat/archivechat/ArchiveChat";
import TermsAndConditionSms from "./TermsAndConditionSms";
import { Link } from "react-router-dom";
import ViewArchiveChat from "./smschat/ViewArchiveChat";

const queryParamsList = [
	"_limitlist",
	"_orderlist",
	"_sortlist",
	"qlist",
	"_pagelist",
	"added_datetimelist",
];

const queryParamsArchive = [
	"_limitarchive",
	"_orderarchive",
	"_sortarchive",
	"qarchive",
	"_pagearchive",
	"added_datetimearchive",
];

const uid = () =>
	String(
		Date.now().toString(32) +
		Math.random().toString(16)
	).replace(/\./g, '');

const generateMask = (phoneNumberValue) => {
	phoneNumberValue = "(" + phoneNumberValue;
	let l30 = phoneNumberValue.length;
	let p30 = phoneNumberValue.substring(0, 4);
	p30 = p30 + ")";

	let p31 = phoneNumberValue.substring(4, l30);
	let pp = p30 + p31;

	let l40 = pp.length;
	let p40 = pp.substring(0, 8);
	p40 = p40 + "-";

	let p41 = pp.substring(8, l40);
	let ppp = p40 + p41;
	let maxphonelength = 13;
	return ppp.substring(0, maxphonelength);
}
const phoneFormat = (e) => {
	let phone = e.target.value;
	let p = phone;

	p = p.replace(/[^\d]*/gi, "");
	if (p.length < 3) {
		return p;
	} else if (p.length == 3) {
		let pp = p;
		let d4 = p.indexOf("(");
		let d5 = p.indexOf(")");
		if (d4 == -1) {
			pp = "(" + pp;
		}
		if (d5 == -1) {
			pp = pp + ")";
		}
		return pp;
	} else if (p.length > 3 && p.length < 7) {
		p = "(" + p;
		let l30 = p.length;
		let p30 = p.substring(0, 4);
		p30 = p30 + ")";

		let p31 = p.substring(4, l30);
		let pp = p30 + p31;

		return pp;
	} else if (p.length >= 7) {
		p = "(" + p;
		let l30 = p.length;
		let p30 = p.substring(0, 4);
		p30 = p30 + ")";

		let p31 = p.substring(4, l30);
		let pp = p30 + p31;

		let l40 = pp.length;
		let p40 = pp.substring(0, 8);
		p40 = p40 + "-";

		let p41 = pp.substring(8, l40);
		let ppp = p40 + p41;
		let maxphonelength = 13;
		let finalPhone = ppp.substring(0, maxphonelength);
		return finalPhone;
	}
};

// const renderValidity = () => {
// 	return [...Array(20).keys()].map((hourNo, index) => {
// 		return <option key={index + 1} value={hourNo + 1}>{hourNo + 1}</option>
// 	})
// }


class LiveChat extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: "",
      updateComponent: new Date().getTime(),
			chat: [],
			chatLeftList: [],
			chatRepondedTimeWise: [],
			chatRemain: [],
			assetPath: props.user.assetPath,
			name: [],
			waittime: [],
			showLeftBarArr: [],
			hippaOn: false,
			modalstatusAddEdit: false,
			modalstatusHIPAA: false,
			modaltitle: "",
			hipaaValidateObject: {},
			user: props.user,
			blankBoxStyle: "block",
			automatedChatWaitTimeBar: "none",
			displayEmogies: "none",
			textAreaVal: [],
			show: false,
			addQuickText: false,
			quickTextOption: [],
			NotificationMemberListOption: [],
			quickSelectVal: "",
			NotificationMemberSelectVal: "",
			join_into_conversation_fromID: "",
			join_into_conversation_assignedID: "",
			join_into_conversation: "",
			anonymous_user_id: "",
			displayTextArea: "none",
			tmpVarVal: "",
			LeftSideAccordionWaitActive: false,
			LeftSideAccordionCurrentlyChattingActive: false,
			DialogBoxConfirmation: {},
      deleteMessageConfirmationDialog:false,
	  deletedChatAnonymousId:[],
      chatIdToBeDeleted:"",
			showSoundNotiDialog: false,
			modalstatusNotiAandMisc: false,
			soundNotiAndSettingmodalData: {
				sound_notification: props.user.sound_notification,
				sound_notificationChecked:
					props.user.sound_notification == 1 ? true : false,
				first_time_sound_notification: props.user.first_sound_notification,
				first_time_sound_notificationChecked:
					props.user.first_sound_notification == 1 ? true : false,
			},
			_sortlist: "id",
			_pagelist: 1,
			_orderlist: "desc",
			_limitlist: 10,
			qlist: "",
			added_datetimelist: "",
			listLoading: false,
			_sortarchive: "id",
			_pagearchive: 1,
			_orderarchive: "desc",
			_limitarchive: 10,
			qarchive: "",
			added_datetimearchive: "",
			archiveLoading: false,
			loading: false,
			textMessage: '',
			activeIndex: null,
			showModelSendText: false,
			sendTextModalTital: 'Send Message',
			sendTextModalLoading: false,
			fromNumber: '',
			trumiaPhone: null,
			allListNumber: null,
			selectedOption: [],
			selectedFile: null,
			csvdata: null,
			uploadCsvBox: false,
			csvError: false,
			csvDisabled: true,
			customInput: [],
			shortnerBox: false,
			validityinhour: '',
			smsTextContent: '',
			textContentError: false,
			uploadtemplate: '',
			errorsMmsUpload: '',
			acceptTerms: false,
			termAcceptError: false,
			viewRecipientModalLoading: false,
			viewRecipientModalTital: 'View all recipients',
			showModelRecipient: false,
			team: [],
			customInputError: false,
			recipientError: false,
			validityinhourError: false,
			fromNumberError: false,
			responsemsg: '',
			smsbtndisabled: false,
			allTextSendList: null,
			smsArchiveList: null,
			allTextSendView: false,
			smsArchiveView: false,
			commonModelLoading: false,
			commonmodelshow: false,
			commonModelTital: '',
			commonmodelBody: null,
			commonmodelFooter: null,
			commonModalId: null,
			commonmodelSaveButtonDisabled: false,
			changeUserNameError: '',
			newUserNameValue: '',
			changeNameTital: '',
			changeNameshow: false,
			changeNameLoading: false,
			changeNameRes: '',
			uploadCsvLoading: false,
			loadingSendText: false,
			trumpiaNumberNotFound: false,
			liveChatTab : 'web',
                        loading: false
		};

		this.myRef = React.createRef();
		var stateObjRef = this;
		setInterval(function () {
			if (stateObjRef.state.waittime.length > 0) {
				for (var i in stateObjRef.state.waittime) {
					var actualDateAndTime = stateObjRef.state.waittime[i].actualDateAndTime;
					var currentTime = Math.floor(Date.now() / 1000);
					var diff = currentTime - actualDateAndTime;
					if (diff > 3600) {
						try {
							stateObjRef.state.chat = stateObjRef.state.chat.filter(function (obj) {
								return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
							});
							stateObjRef.state.chatLeftList = stateObjRef.state.chatLeftList.filter(function (obj) {
								return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
							});


						} catch (e) {
							stateObjRef.setState({
								chat: stateObjRef.state.chat,
								chatLeftList: stateObjRef.state.chatLeftList,
							})
						}
						try {
							stateObjRef.state.chatRepondedTimeWise = stateObjRef.state.chatRepondedTimeWise.filter(function (obj) {
								return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
							});
							stateObjRef.setState({
								chatRepondedTimeWise: stateObjRef.state.chatRepondedTimeWise,
							})
						} catch (e) {

						}

						try {
							stateObjRef.state.waitTime = stateObjRef.state.waitTime.filter(function (obj) {
								return obj.anonymous_user_id !== stateObjRef.state.waittime[i].anonymous_user_id;
							});
							stateObjRef.setState({
								waitTime: stateObjRef.state.waitTime,
							})
						} catch (e) {

						}

						if (i == (stateObjRef.state.waittime.length - 1)) {
							var auid = "";
							try {
								var auid = stateObjRef.state.anonymous_user_id;
								// let liveChatType = stateObjRef.state.liveChatTab;
								// const filterLiveChat = stateObjRef.state.chat.filter((chatItm) => {
								// 	if (liveChatType) {
										
								// 	}
								// })
								var l = stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).length;
								if (l > 0) {
									auid = auid;
								}
							} catch (e) {
								auid = "";
							}
							if (auid == "") {
								if (stateObjRef.state.chat.length != 0) {
									auid = stateObjRef.state.chat[0].anonymous_user_id;
								} else {
									auid = ""
								}
							}
							if (auid == "") {
								stateObjRef.setState({
									blankBoxStyle: "block",
									displayTextArea: "none"

								})

							} else {
								setTimeout(function () {
									try { stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).displayChatContainer = 'block'; } catch (e) { console.log(e) }
									try { stateObjRef.state.chat.find(v => v.anonymous_user_id == auid).displayWholeChatContainer = ''; } catch (e) { }
									try { stateObjRef.state.chatLeftList.find(v => v.anonymous_user_id == auid).leftBarClass = 'kt-notification-v2__item mh-chatLeftBarActive'; } catch (e) { }
									try { stateObjRef.state.chatRepondedTimeWise.find(v => v.anonymous_user_id == auid).leftBarClass = 'kt-notification-v2__item mh-chatLeftBarActive'; } catch (e) { }
									stateObjRef.setState({
										chat: stateObjRef.state.chat,
										chatLeftList: stateObjRef.state.chatLeftList,
										chatRepondedTimeWise: stateObjRef.state.chatRepondedTimeWise,
									})
								}, 2000)
							}
						}

					}

					// stateObjRef.state.waittime[i].time = stateObjRef.state.waittime[i].time + 10;
					stateObjRef.state.waittime[i].displayTime = stateObjRef.secondsToHms(diff);
				}
				stateObjRef.setState({
					waittime: stateObjRef.state.waittime
				});
			}

		}, 10000)
	}

	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);
		var hDisplay = h > 0 ? h : "0";
		var mDisplay = m > 0 ? m : "0";
		var sDisplay = s > 0 ? s : "0";
		var unit = "";
		if (hDisplay != 0) unit = "Hr";
		else if (mDisplay != 0) unit = "Min";
		else unit = "Sec";
		return (
			("0" + hDisplay).slice(-2) +
			" : " +
			("0" + mDisplay).slice(-2) +
			" : " +
			("0" + sDisplay).slice(-2) +
			" " +
			unit
		);
	};

	scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);
	tConvert = (time) => {
		time = time
			.toString()
			.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) {
			// If time format correct
			time = time.slice(1); // Remove full string match value
			time.splice(-1, 1);
			time[4] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(""); // return adjusted time or original string
	};
	dateTime = (dt) => {
		var date = new Date(dt * 1000).toString();
		var time = date.split(" ")[4];
		//alert(dt)
		time = this.tConvert(time);
		var newDate =
			date.split(" ")[1] +
			" " +
			date.split(" ")[2] +
			" " +
			date.split(" ")[3] +
			" " +
			time;
		return newDate;
	};
	// shouldComponentUpdate(nextProps, nextState) {
	//   return false;
	// }
	componentDidMount = () => {
		const stateObjRef = this;
		this.props.socket.on("refresh chatdata_agent_firsttime", function (msg) {
			
			// console.log("i am here on mount", msg)
			// chat arraysdas
			msg["detectedSourceLanguage"] = "en";
			let dataMsg = JSON.parse(msg.message);
			msg["message"] = dataMsg[0];
			let msgLength = dataMsg.length;
			let liveChatId = 0;
			let displayBlank = false; 
			liveChatId = (stateObjRef.state.liveChatTab == 'sms') ? 1 : 0;
			let webChat = stateObjRef.state.chat.filter((itm) => {
				if (itm.from == 0 || itm.from == 'web') {
					return true;
				}
				return false;
			});
			let smsChat = stateObjRef.state.chat.filter((itm) => {
				if (itm.from == 1 || itm.from == 'sms') {
					return true;
				}
				return false;
			});
			// console.log("checking 1", stateObjRef.state.liveChatTab, msg, webChat, smsChat, stateObjRef.liveChatTab, liveChatId);
			// if chat in web
			if (stateObjRef.state.liveChatTab == 'web') {
				// console.log('i am in webchat');
				if (webChat.length === 0) {
					// console.log('i am in webchat', webChat.length);
					if (msg['from'] == stateObjRef.state.liveChatTab || msg['from'] == liveChatId) {
						msg["displayChatContainer"] = "block";
						msg["displayWholeChatContainer"] = "";
						// displayBlank = 
						stateObjRef.setState({
							blankBoxStyle: "none",
							displayTextArea: "",
							anonymous_user_id: msg.anonymous_user_id,
						});
					}
				}else{
					// console.log('i am in webchat', webChat.length);
					msg["displayChatContainer"] = "none";
					msg["displayWholeChatContainer"] = "none";
				}
			}else{
				// console.log('i am in smschat');
				if (smsChat.length === 0) {
					if (msg['from'] == stateObjRef.state.liveChatTab || msg['from'] == liveChatId) {
						msg["displayChatContainer"] = "block";
						msg["displayWholeChatContainer"] = "";
						stateObjRef.setState({
							displayTextArea: "",
							blankBoxStyle: "none",
							anonymous_user_id: msg.anonymous_user_id,
						});
					}
				}else{
					msg["displayChatContainer"] = "none";
					msg["displayWholeChatContainer"] = "none";
				}
			}
			
			if (stateObjRef.state.hippaOn == true) msg["hippaOn"] = "block";
			else msg["hippaOn"] = "none";

			msg["join_into_conversation"] = "0";
			msg["join_into_conversation_fromID"] = "";
			msg["join_into_conversation_assignedID"] = "";
			msg["leftBarClass"] = "kt-notification-v2__item";
			msg["closeConversation"] = "n";
			msg["read"] = "mh-Unread";

			// console.log("displayBlank 1", displayBlank);
			stateObjRef.state.chatLeftList.unshift(msg);
			stateObjRef.state.chat.push(msg);

			// for name Arr
			const object = {
				anonymous_user_id: msg.anonymous_user_id,
				name: msg.name,
				hippaOn: msg.hippaOn,
				welcomeMsg: msg.welcomeMsg,
				from: msg.from
			};
			stateObjRef.state.name.push(object);

			// for waittime arr
			const wtObject = {
				anonymous_user_id: msg.anonymous_user_id,
				time: 0,
				displayTime: "00 : 00 :00 Sec",
				actualDateAndTime: msg.message.time,
			};
			stateObjRef.state.waittime.push(wtObject);

			// for remain chat
			stateObjRef.state.chatRemain[msg.anonymous_user_id] = [];
			if (msgLength > 1) {
				for (let i = 1; i < msgLength; i++) {
					var obj = {
						anonymous_user_id: msg.anonymous_user_id,
						button_color: msg.button_color,
						cahtversion: msg.cahtversion,
						chatBot_id: msg.chatBot_id,
						chat_logo: msg.chat_logo,
						company: msg.company,
						detectedSourceLanguage: msg.detectedSourceLanguage,
						from: msg.from,
						hippaOn: msg.hippaOn,
						liveusercreatedon: "",
						message: dataMsg[i],
						name: msg.name,
						username: msg.username,
						welcomeMsg: msg.welcomeMsg,
					};
					stateObjRef.state.chatRemain[msg.anonymous_user_id].push(obj);
				}
			}

			//for Misc
      if(stateObjRef.state.textAreaVal[msg?.anonymous_user_id] ==null || stateObjRef.state.textAreaVal[msg?.anonymous_user_id] == "undefined"){

      //   stateObjRef.state.textAreaVal[msg?.anonymous_user_id] = "a";
      }else{
        stateObjRef.state.textAreaVal[msg?.anonymous_user_id] = "a";
      }

			stateObjRef.setState({
				chat: stateObjRef.state.chat,
				chatLeftList: stateObjRef.state.chatLeftList,
				name: stateObjRef.state.name,
				waittime: stateObjRef.state.waittime,
				blankBoxStyle: stateObjRef.state.blankBoxStyle,
				automatedChatWaitTimeBar: "block",
				chatRemain: stateObjRef.state.chatRemain,
				displaEmogies: stateObjRef.state.displaEmogies,
				textAreaVal: stateObjRef.state.textAreaVal,
			}, 
				this.setChatArea
			);
		});

		this.props.socket.on("refresh chatdata_agent", function (msg) {
			// console.log("message", msg)
			if (stateObjRef.state.chatRemain.hasOwnProperty(msg.anonymous_user_id)) {
				stateObjRef.state.chatRemain[msg.anonymous_user_id].push(msg);
			}else{
				stateObjRef.state.chatRemain[msg.anonymous_user_id] = [];
				stateObjRef.state.chatRemain[msg.anonymous_user_id].push(msg);
			}
			stateObjRef.setState({
				chatRemain: stateObjRef.state.chatRemain,
			});
		});

		this.props.socket.on("refresh chat_user_name", function (msg) {
			stateObjRef.state.name = stateObjRef.state.name.filter(function (obj) {
				return obj.anonymous_user_id !== msg.anonymous_user_id;
			});

			var hippaOn = stateObjRef.state.chat.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).hippaOn;

			// if (stateObjRef.state.hippaOn == true) 
			// msg["hippaOn"] = "block";
			// else 
			// msg["hippaOn"] = "none";
			msg["hipaaOn"] = hippaOn;
			const object = {
				anonymous_user_id: msg.anonymous_user_id,
				name: msg.name,
				hippaOn: hippaOn,
				welcomeMsg: msg.welcomeMsg,
				from: msg.from
			};
			// console.log(object)
			stateObjRef.state.name.push(object);
			stateObjRef.setState({
				name: stateObjRef.state.name,
			});
		});

		this.props.socket.on("refresh sibling_chat", function (msg) {
			if (stateObjRef.state.tmpVarVal == msg.tmpVarVal) {
			} else {
		const deletedIdExists = stateObjRef.state.deletedChatAnonymousId.includes(msg.anonymous_user_id)

        if(!deletedIdExists){
				if (typeof msg.jumpIn !== undefined && msg.jumpIn == true) {
					stateObjRef.siblingJumpin(msg)
				}
				stateObjRef.state.chatRemain[msg.anonymous_user_id].push(msg);
				stateObjRef.setState({
					chatRemain: stateObjRef.state.chatRemain,
			  blankBoxStyle:"none"
				});
			}
      }
		});

		this.props.socket.on("user_typing refresh", function (msg) {
			try {
				document.getElementById(
					"loader-" + msg.anonymous_user_id
				).style.display = "block";
			} catch (e) { }
		});

		this.props.socket.on("user_typing_stop refresh", function (msg) {
			try {
				document.getElementById(
					"loader-" + msg.anonymous_user_id
				).style.display = "none";
			} catch (e) { }
		});

		// set page title configuration
		this.props.setPageTitle({
			title: "Live Chat",
			subHeaderTitle: "Conversations",
			navigationName: "liveChat",
		});

		this.props.toggleNavigation({ toggle: true });
		this.props.toggleBreadcrum({ toggle: false });

		// get hipaa info
		api.user
			.getHippaConfBots({ chatBot_id: this.props.user.chatBot_id })
			.then((resp) => {
				if (resp.data && resp.data.data.hippaConfBot) {
					var hippaBots = resp.data.data.hippaConfBot;
					if (hippaBots.includes(this.props.user.chatVersion)) {
						this.setState({ hippaOn: true });
					}
				}
			})
			.catch((e) => {
				console.log(e);
			});

		//get live chat list on refresh the page
		this.loadLiveChatDataFromServer();

		this.setQuickTextForLiveChat();
		this.NotificationMemberList();
	};
	// this is for set tab area
	setChatArea = (type = '') => {
		let tabID = this.state.liveChatTab;
		const chat =  this.state.chat;
		// console.log("chatFilterForTab 3", chat, type);
		let fromID = 0;
		// alert();
		if (type === 'sms') {
			fromID = 1;
		}
		if (tabID === 'sms') {
			fromID = 1;
		}
		// console.log("checking from", chat, tabID, fromID,);
		let chatFilterForTab = chat.filter((item) => {
			if (item.from == tabID || item.from == fromID) {
				return true;
			}else{
				return false;
			}
		});
		// console.log("chatFilterForTab", chatFilterForTab, tabID, type);
		if (chatFilterForTab.length === 0) {
			// console.log("i am here in block")
			this.setState({
				displayTextArea: "none",
				blankBoxStyle: "block",
				join_into_conversation: 0,
				join_into_conversation_assignedID: '',
				join_into_conversation_fromID: ''
			});
		}else{
			// console.log("i am here in none", chatFilterForTab, tabID)
			this.setState({
				displayTextArea: "",
				blankBoxStyle: "none"
			});
			// if (type) {
				this.openLiveChatBoxOnClickLeftRow(chatFilterForTab[0].anonymous_user_id, this.state.hippaOn)
			// }
		}
	}

	loadLiveChatDataFromServer = (chatAnonymousId = null) => {
		const stateObjRef = this;
		api.liveChat
			.userChatList({ chatBot_id: this.props.user.chatBot_id })
			.then((resp) => {
				// console.log("on refresh", resp.data.data.chatLeftList);
				if (resp.data && resp.data.status) {
					if (resp.data.data.chat.length > 0) {
						this.setState({ loading: true })
						var anonymous_user_id = "";
						var LeftSideAccordionWaitActive = false;
						var LeftSideAccordionCurrentlyChattingActive = false;
						if (resp.data.data.AutomatedChatFirstAnoId != "0") {
							anonymous_user_id = resp.data.data.AutomatedChatFirstAnoId;
							LeftSideAccordionWaitActive = true;
							LeftSideAccordionCurrentlyChattingActive = false;
							try {
								resp.data.data.chatLeftList.find(
									(v) => v.anonymous_user_id == anonymous_user_id
								).leftBarClass =
									"kt-notification-v2__item mh-chatLeftBarActive";
							} catch (e) { }
						} else if (resp.data.data.currentlyChattingFirstAnoId != "0") {
							anonymous_user_id = resp.data.data.currentlyChattingFirstAnoId;
							LeftSideAccordionWaitActive = false;
							LeftSideAccordionCurrentlyChattingActive = true;
							try {
								resp.data.data.chatRepondedTimeWise.find(
									(v) => v.anonymous_user_id == anonymous_user_id
								).leftBarClass =
									"kt-notification-v2__item mh-chatLeftBarActive";
							} catch (e) { }
						}
						try {
							resp.data.data.chat.find(
								(v) => v.anonymous_user_id == anonymous_user_id
							).displayChatContainer = "block";
						} catch (e) {
							console.log(e);
						}
						try {
							resp.data.data.chat.find(
								(v) => v.anonymous_user_id == anonymous_user_id
							).displayWholeChatContainer = "";
						} catch (e) { }
						var join_into_conversation = resp.data.data.chat.find(
							(v) => v.anonymous_user_id == anonymous_user_id
						).join_into_conversation;
						var join_into_conversation_fromID = resp.data.data.chat.find(
							(v) => v.anonymous_user_id == anonymous_user_id
						).join_into_conversation_fromID;
						var join_into_conversation_assignedID = resp.data.data.chat.find(
							(v) => v.anonymous_user_id == anonymous_user_id
						).join_into_conversation_assignedID;

						var closeConversation = resp.data.data.chat.find(
							(v) => v.anonymous_user_id == anonymous_user_id
						).closeConversation;
						// console.log("on refresh 2", resp.data.data.chatLeftList);
						// console.log("all chat response", resp.data.data.chatRepondedTimeWise);
						stateObjRef.setState(
							{
								chat: resp.data.data.chat,
								chatLeftList: resp.data.data.chatLeftList,
								name: resp.data.data.name,
								chatRemain: resp.data.data.chatRemain,
								blankBoxStyle: "none",
								automatedChatWaitTimeBar: "block",
								textAreaVal: resp.data.data.textAreaVal,
								waittime: resp.data.data.waitTime,
								chatRepondedTimeWise: resp.data.data.chatRepondedTimeWise,
								join_into_conversation_fromID: join_into_conversation_fromID,
								join_into_conversation_assignedID: join_into_conversation_assignedID,
								join_into_conversation: join_into_conversation,
								closeConversation: closeConversation,
								anonymous_user_id: anonymous_user_id,
								displayTextArea: "",
								LeftSideAccordionWaitActive: LeftSideAccordionWaitActive,
								LeftSideAccordionCurrentlyChattingActive: LeftSideAccordionCurrentlyChattingActive,
							},
							this.completeSetState
						);
					} else {
						stateObjRef.setState({
							LeftSideAccordionWaitActive: true,
							LeftSideAccordionCurrentlyChattingActive: false,
						});
					}
				} else {
				}
			})
			.catch((e) => {
				console.log(e);
			});

    this.setQuickTextForLiveChat();
    this.NotificationMemberList();
  };
  //func to hide single chat
  deleteSingleChat = (e,id)=>{

    this.setState({loading:true,deleteMessageConfirmationDialog:false})
    api.liveChat.deleteSingleChat({id:id}).then(data=>{
      if(data.data.status){
        
        // api.liveChat
        // .userChatList({ chatBot_id: this.props.user.chatBot_id })
        // .then((resp) => {
        //   if (resp.data && resp.data.status) {
        //     if (resp.data.data.chat.length > 0) {
        //       var anonymous_user_id = "";
        //       var LeftSideAccordionWaitActive = false;
        //       var LeftSideAccordionCurrentlyChattingActive = false;
        //       if (resp.data.data.AutomatedChatFirstAnoId != "0") {
        //         anonymous_user_id = resp.data.data.AutomatedChatFirstAnoId;
        //         LeftSideAccordionWaitActive = true;
        //         LeftSideAccordionCurrentlyChattingActive = false;
        //         resp.data.data.chatLeftList.find(
        //           (v) => v.anonymous_user_id == anonymous_user_id
        //         ).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
        //       } else if (resp.data.data.currentlyChattingFirstAnoId != "0") {
        //         anonymous_user_id = resp.data.data.currentlyChattingFirstAnoId;
        //         LeftSideAccordionWaitActive = false;
        //         LeftSideAccordionCurrentlyChattingActive = true;

        //           resp.data.data.chatRepondedTimeWise.find(
        //             (v) => v.anonymous_user_id == anonymous_user_id
        //           ).leftBarClass =
        //             "kt-notification-v2__item mh-chatLeftBarActive";
        //       }
        //         resp.data.data.chat.find(
        //           (v) => v.anonymous_user_id == anonymous_user_id
        //         ).displayChatContainer = "block";
        //         resp.data.data.chat.find(
        //           (v) => v.anonymous_user_id == anonymous_user_id
        //         ).displayWholeChatContainer = "";
        //       var join_into_conversation = resp.data.data.chat.find(
        //         (v) => v.anonymous_user_id == anonymous_user_id
        //       ).join_into_conversation;
        //       var join_into_conversation_fromID = resp.data.data.chat.find(
        //         (v) => v.anonymous_user_id == anonymous_user_id
        //       ).join_into_conversation_fromID;
        //       var join_into_conversation_assignedID = resp.data.data.chat.find(
        //         (v) => v.anonymous_user_id == anonymous_user_id
        //       ).join_into_conversation_assignedID;
  
        //       var closeConversation = resp.data.data.chat.find(
        //         (v) => v.anonymous_user_id == anonymous_user_id
        //       ).closeConversation;
  
        //       stateObjRef.setState(
        //         {
        //           chat: resp.data.data.chat,
        //           chatLeftList: resp.data.data.chatLeftList,
        //           name: resp.data.data.name,
        //           chatRemain: resp.data.data.chatRemain,
        //           blankBoxStyle: "none",
        //           automatedChatWaitTimeBar: "block",
        //           textAreaVal: resp.data.data.textAreaVal,
        //           waittime: resp.data.data.waitTime,
        //           chatRepondedTimeWise: resp.data.data.chatRepondedTimeWise,
        //           join_into_conversation_fromID: join_into_conversation_fromID,
        //           join_into_conversation_assignedID: join_into_conversation_assignedID,
        //           join_into_conversation: join_into_conversation,
        //           closeConversation: closeConversation,
        //           anonymous_user_id: anonymous_user_id,
        //           displayTextArea: "",
        //           LeftSideAccordionWaitActive: LeftSideAccordionWaitActive,
        //           LeftSideAccordionCurrentlyChattingActive: LeftSideAccordionCurrentlyChattingActive,
        //         },
        //         this.completeSetState
        //       );
        //     } else {
        //       stateObjRef.setState(
        //         {
        //           chat: resp.data.data.chat,
        //           chatLeftList: resp.data.data.chatLeftList,
        //           name: resp.data.data.name,
        //           chatRemain: resp.data.data.chatRemain,
        //           blankBoxStyle: "block",
        //           automatedChatWaitTimeBar: "none",
        //           textAreaVal: resp.data.data.textAreaVal,
        //           waittime: resp.data.data.waitTime,
        //           chatRepondedTimeWise: resp.data.data.chatRepondedTimeWise,
        //           displayTextArea: "none",
        //           LeftSideAccordionWaitActive: true,
        //           LeftSideAccordionCurrentlyChattingActive: false,
        //         },
        //         this.completeSetState
        //       );
        //     }
        //   } else {
        //   }
        // })
        // .catch((e) => {
        //   console.log(e);
        // });
    const  modifiedDeletedChatAnonymousId = [...this.state.deletedChatAnonymousId,id]
      const modifiedChatList = this.state.chat.filter(user=>user.anonymous_user_id!=id)
      const modifiedChatLeftList = this.state.chatLeftList.filter(user=>user.anonymous_user_id!=id)
	//   if()
      const tempChatRemain = {...this.state.chatRemain}
      delete tempChatRemain[id]
      const modifiedChatRemain = {...tempChatRemain}
      const modifiedChatRepondedTimeWise = this.state.chatRepondedTimeWise.filter(user=>user.anonymous_user_id!=id)
      const modifiedWaittime = this.state.waittime.filter(user=>user.anonymous_user_id!=id)
    //   console.log("chat",modifiedChatList)
    //   console.log("chatLeftList",modifiedChatLeftList)
    //   console.log("chatRemain",modifiedChatRemain)
    //   console.log("chatRepondedTimeWise",modifiedChatRepondedTimeWise)
    //   console.log("waittime",modifiedWaittime)
      this.setState({
        chat:modifiedChatList,
        chatLeftList:modifiedChatLeftList,
        chatRemain:modifiedChatRemain,
        chatRepondedTimeWise:modifiedChatRepondedTimeWise,
        waittime:modifiedWaittime,
		deletedChatAnonymousId:modifiedDeletedChatAnonymousId,
        loading:false,
      },()=>{
		if(this.state.chat.length===0){
			this.setState({
				displayTextArea:"none",
        		blankBoxStyle:"block"
			})
		}else{
			let liveChatId = (this.state.liveChatTab == 'sms') ? 1 : 0;
			let webChat = this.state.chat.filter((itm) => {
				if (itm.from == 0 || itm.from == 'web') {
					return true;
				}
				return false;
			});
			let smsChat = this.state.chat.filter((itm) => {
				if (itm.from == 1 || itm.from == 'sms') {
					return true;
				}
				return false;
			});
			if(liveChatId){
				if (smsChat.length === 0){
					this.setState({
						displayTextArea:"none",
						blankBoxStyle:"block",
						join_into_conversation: 0,
						join_into_conversation_assignedID: '',
						join_into_conversation_fromID: ''
					})
				}else{
					this.openLiveChatBoxOnClickLeftRow(smsChat[0].anonymous_user_id,this.state.hippaOn)
				}
			}else{
				if (webChat.length === 0){
					this.setState({
						displayTextArea:"none",
						blankBoxStyle:"block",
						join_into_conversation: 0,
						join_into_conversation_assignedID: '',
						join_into_conversation_fromID: ''
					})
				}else{
					this.openLiveChatBoxOnClickLeftRow(webChat[0].anonymous_user_id,this.state.hippaOn)
				}
			}
			// this.openLiveChatBoxOnClickLeftRow(this.state.chat[0].anonymous_user_id,this.state.hippaOn)
	}
	  })
      }
    }).catch(err=>
      console.log(err)
    )
  }

  closeModal = ()=>{
    this.setState({deleteMessageConfirmationDialog:false})
  }

  showDeleteConfimationModal = (e,id)=>{
    this.setState({deleteMessageConfirmationDialog:true,chatIdToBeDeleted:id})
  }

	siblingJumpin = (msg) => {
		// console.log("sibling", msg);
		var d = new Date();
		var dateUTC = d.toUTCString();
		var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
		var hippaOn = "none";
		if (this.state.hippaOn == true) {
			hippaOn = "block";
		}
		var leftBarClass = "kt-notification-v2__item";
		var join_into_conversation = "1";
		var findAnoId = 0;
		try {
			var findAnoIdOnj = this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			);
			Object.size = function (obj) {
				var size = 0,
					key;
				for (key in obj) {
					if (obj.hasOwnProperty(key)) size++;
				}
				return size;
			};

			findAnoId = Object.size(findAnoIdOnj);
		} catch (e) { }

		const object = {
			anonymous_user_id: msg.anonymous_user_id,
			hippaOn: hippaOn,
			from: 0,
			leftBarClass: leftBarClass,
			join_into_conversation: join_into_conversation,
			lastRespondedAt: "" + dateUTCUnixTimeStamp + "",
		};
		if (findAnoId == 0) {
			this.state.chatRepondedTimeWise.push(object);
		} else {
			try {
				this.state.chatRepondedTimeWise.find(
					(v) => v.anonymous_user_id == msg.anonymous_user_id
				).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
			} catch (e) { }
		}
		this.state.waittime.find(
			(v) => v.anonymous_user_id == msg.anonymous_user_id
		).time = "0";
		this.state.waittime.find(
			(v) => v.anonymous_user_id == msg.anonymous_user_id
		).displayTime = "00 : 00 : 00 Sec";
		this.state.waittime.find(
			(v) => v.anonymous_user_id == msg.anonymous_user_id
		).actualDateAndTime = dateUTCUnixTimeStamp;
		this.state.chatRepondedTimeWise.sort((a, b) =>
			Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
		);

		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).join_into_conversation_fromID = '0';
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).join_into_conversation_assignedID = '0'
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).join_into_conversation = 1;
		} catch (e) { }
		try {
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).join_into_conversation = 1;
		} catch (e) { }

		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }

		try {
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }


		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == msg.anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }


		this.setState({
			chatRepondedTimeWise: this.state.chatRepondedTimeWise,
			waittime: this.state.waittime,
			// join_into_conversation_fromID: '0',
			// join_into_conversation_assignedID: '0',
			join_into_conversation: 1,
			chat: this.state.chat,
			chatLeftList: this.state.chatLeftList
		});
	}

	completeSetState = (e) => {
		var thisV = this;
		this.setChatArea();
		setTimeout(function () {
			thisV.setState({ loading: false })
		}, 100);

	};
	renderName(anonymousUserId, namePre) {
		const { name } = this.state;
		return name.map(({ anonymous_user_id, name }, idx) =>
			anonymousUserId == anonymous_user_id ? (
				<span className="kt-widget3__username">{name}</span>
			) : (
				""
			)
		);
	}

	openLiveChatBoxOnClickLeftRow = (anonymous_user_id, hippaOn) => {
		if (hippaOn == "block") {
			this.openHipaaBox(anonymous_user_id);
		} else {
			try {
				this.state.chat.find(
					(v) => v.displayChatContainer == "block"
				).displayChatContainer = "none";
			} catch (e) { }
			try {
				this.state.chat.find(
					(v) => v.displayWholeChatContainer == ""
				).displayWholeChatContainer = "none";
			} catch (e) { }
			try {
				this.state.chatLeftList.find(
					(v) =>
						v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
				).leftBarClass = "kt-notification-v2__item";
			} catch (e) { }
			try {
				this.state.chatRepondedTimeWise.find(
					(v) =>
						v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
				).leftBarClass = "kt-notification-v2__item";
			} catch (e) { }

			try {
				this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).displayChatContainer = "block";
			} catch (e) { }
			try {
				this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).displayWholeChatContainer = "";
			} catch (e) { }
			try {
				this.state.name.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).hippaOn = "none";
			} catch (e) { }
			try {
				this.state.chatLeftList.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
			} catch (e) { }
			try {
				this.state.chatRepondedTimeWise.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
			} catch (e) { }

			this.setState({
				chat: this.state.chat,
				chatLeftList: this.state.chatLeftList,
				name: this.state.name,
				join_into_conversation_fromID: this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).join_into_conversation_fromID,
				join_into_conversation_assignedID: this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).join_into_conversation_assignedID,
				join_into_conversation: this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).join_into_conversation,
				anonymous_user_id: anonymous_user_id,
				closeConversation: this.state.chat.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).closeConversation,

			});
			document.getElementById(this.state.anonymous_user_id).value = "";

			//for read
			this.readChatFromLeft(anonymous_user_id)
		}
	};

	readChatFromLeft = (anonymous_user_id) => {
		var found = this.state.chat.find((v) => v.anonymous_user_id == anonymous_user_id).read
		if (found != "") {
			api.liveChat
				.updateRead({
					anonymous_user_id: anonymous_user_id,

				})
				.then((resp) => { });
		}

		this.state.chat.find((v) => v.anonymous_user_id == anonymous_user_id).read = "";
		this.state.chatLeftList.find((v) => v.anonymous_user_id == anonymous_user_id).read = "";

		this.setState({
			chat: this.state.chat,
			chatLeftList: this.state.chatLeftList,
		})
	}

	onValidHipaaSubmit = (anonymous_user_id) => {
		try {
			this.state.chat.find(
				(v) => v.displayChatContainer == "block"
			).displayChatContainer = "none";
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.displayWholeChatContainer == ""
			).displayWholeChatContainer = "none";
		} catch (e) { }
		// try {this.state.chat.find(v => v.hippaOn == "none").hippaOn = 'block';} catch (e) {}
		// try {this.state.chatRepondedTimeWise.find(v => v.hippaOn == "none").hippaOn = 'block';} catch (e) {}
		try {
			this.state.chatLeftList.find(
				(v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
			).leftBarClass = "kt-notification-v2__item";
		} catch (e) { }
		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
			).leftBarClass = "kt-notification-v2__item";
		} catch (e) { }

		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).displayWholeChatContainer = "";
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).displayChatContainer = "block";
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";

		} catch (e) { }
		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }
		try {
			this.state.name.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }
		try {
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
		} catch (e) { }
		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
		} catch (e) { }

		this.setState({
			chat: this.state.chat,
			chatLeftList: this.state.chatLeftList,
			modalstatusHIPAA: false,
			name: this.state.name,
		});
		document.getElementById(this.state.anonymous_user_id).value = "";
		this.readChatFromLeft(anonymous_user_id)

	};
	modalClose = () => {
		this.setState({
			modalstatusHIPAA: false,
		});
	};
	openHipaaBox = (anonymous_user_id, callback) => {

		// this.setState({ loading: true })
		api.manageUser.checkHipaaAccount({ user_id: this.props.user.user_id, email: this.props.user.email }).then((data) => {
			try {
				if (data.data.data.subaccount_id == "found") {
					var type = "validate";
				} else {
					var type = "register";
				}
				this.setState({
					join_into_conversation_fromID: this.state.chat.find(
						(v) => v.anonymous_user_id == anonymous_user_id
					).join_into_conversation_fromID,
					join_into_conversation_assignedID: this.state.chat.find(
						(v) => v.anonymous_user_id == anonymous_user_id
					).join_into_conversation_assignedID,
					join_into_conversation: this.state.chat.find(
						(v) => v.anonymous_user_id == anonymous_user_id
					).join_into_conversation,
					anonymous_user_id: anonymous_user_id,
					closeConversation: this.state.chat.find(
						(v) => v.anonymous_user_id == anonymous_user_id
					).closeConversation,
					modalstatusHIPAA: true,
					modaltitle:
						"To view sensitive chat information, please enter your authorize email and PIN",
					hipaaValidateObject: {
						userId: this.props.user.user_id,
						userType: "User",
						via: "user",
						auto_id: anonymous_user_id,
						pin_email: this.props.user.email,
						refId: anonymous_user_id,
						chat_section: "livechat",
						type: type,
						loading: false

					},
				});
			} catch (err) { }
		});



	};
	openHipaaAfterRegister = auto_id => {
		this.setState({
			loading: false,
			modalstatusHIPAA: true,
			modaltitle:
				"To view sensitive chat information, please enter your authorize email and PIN",
			hipaaValidateObject: {
				userId: this.props.user.user_id,
				userType: "User",
				via: "user",
				auto_id: auto_id,
				pin_email: this.props.user.email,
				refId: auto_id,
				chat_section: "chatlist",
				type: "validate"

			},
		});
	}
	toggleEmogiesBar = (display, anonymous_user_id) => {
		if (display == "none") display = "block";
		else display = "none";
		this.state.displayEmogies = display;
		this.setState({
			displayEmogies: this.state.displayEmogies,
		});
	};

	addChatData = (anonymous_user_id, liveChatObject) => {
		liveChatObject.cahtversion = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).cahtversion;
		liveChatObject.detectedSourceLanguage = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).detectedSourceLanguage;
		liveChatObject.from = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).from;
		liveChatObject.hippaOn = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).hippaOn;
		liveChatObject.username = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).username;
		liveChatObject.welcomeMsg = this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).welcomeMsg;
		this.state.chatRemain[anonymous_user_id].push(liveChatObject);
		this.state.chat.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).join_into_conversation = 1;
		this.setState({
			chatRemain: this.state.chatRemain,
			chat: this.state.chat,
			quickSelectVal: "",
		});

		try {
			document.querySelector(".css-1qyrjaz").click();
		} catch (e) { }
	};

	sendJumpingMessage = (
		company,
		chatBot_id,
		chat_logo,
		primary_color,
		anonymous_user_id,
		commenttext,
		sendTextInProcess,
		socket
	) => {
		console.log("joininto sendJumpingMessage", company,
		chatBot_id,
		chat_logo,
		primary_color,
		anonymous_user_id,
		commenttext,
		sendTextInProcess,
		socket);
		if (sendTextInProcess == "Y") {
			return false;
		}
		if (commenttext == null) {
      var comment_text = "";
		} else {
			var comment_text = commenttext;
		}
		comment_text = comment_text.replace(/\\&#39;/g, "'");
		// comment_text =  comment_text.replace("\&#39;","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		// comment_text =  comment_text.replace("\'","'");
		var comment_text = comment_text.replace("&#39;", "'");

		var liveChatObject = {};
		liveChatObject.anonymous_user_id = anonymous_user_id;
		liveChatObject.name = this.state.name.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).name;
		liveChatObject.chatBot_id = chatBot_id;
		var d = new Date();
		var dateUTC = d.toUTCString();
		var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
		var liveChatMessage = {
			From: "a",
			msgVal: comment_text,
			time: dateUTCUnixTimeStamp,
		};
		liveChatObject.message = liveChatMessage;
		liveChatObject.button_color = primary_color;
		liveChatObject.chat_logo = chat_logo;
		liveChatObject.company = company;
		liveChatObject.liveusercreatedon = dateUTCUnixTimeStamp;
		liveChatObject.jumpIn = true;
		console.log('i am here 1', comment_text);
    if(comment_text.trim().length>0){
		socket.emit("live_chat_from_agent added", liveChatObject);
    }
	console.log('i am here 2', comment_text);
		var tmpVarVal =
			Math.floor(Math.random() * 100000000 + 1) +
			"_" +
			Math.round(new Date().getTime() / 1000);

		liveChatObject.tmpVarVal = tmpVarVal;
		socket.emit("live_chat_from_agent_again added", liveChatObject);
		this.addChatData(anonymous_user_id, liveChatObject);
		var hippaOn = "none";
		if (this.state.hippaOn == true) {
			hippaOn = "block";
		}
		var leftBarClass = "kt-notification-v2__item";
		var join_into_conversation = "1";
		var findAnoId = 0;
		try {
			var findAnoIdOnj = this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			);
			Object.size = function (obj) {
				var size = 0,
					key;
				for (key in obj) {
					if (obj.hasOwnProperty(key)) size++;
				}
				return size;
			};

			findAnoId = Object.size(findAnoIdOnj);
		} catch (e) { }
		let fromMsg = this.state.chatLeftList.find((chatlist) => chatlist.anonymous_user_id === anonymous_user_id);
		const object = {
			anonymous_user_id: anonymous_user_id,
			hippaOn: hippaOn,
			leftBarClass: leftBarClass,
			join_into_conversation: join_into_conversation,
			lastRespondedAt: "" + dateUTCUnixTimeStamp + "",
			from: fromMsg.from
		};
		if (findAnoId == 0) {
			this.state.chatRepondedTimeWise.push(object);
		} else {
			try {
				this.state.chatRepondedTimeWise.find(
					(v) => v.anonymous_user_id == anonymous_user_id
				).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
			} catch (e) { }
		}
		console.log(" i am here to set state", this.props.user.user_id);
		this.setState({
			join_into_conversation_fromID: this.props.user.user_id,
			join_into_conversation_assignedID: this.props.user.user_id,
			join_into_conversation: 1,
		});
		console.log(" i am here to set state 2", this.props.user.user_id);
		this.state.waittime.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).time = "0";
		this.state.waittime.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).displayTime = "00 : 00 : 00 Sec";
		this.state.waittime.find(
			(v) => v.anonymous_user_id == anonymous_user_id
		).actualDateAndTime = dateUTCUnixTimeStamp;
		this.state.chatRepondedTimeWise.sort((a, b) =>
			Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
		);
		// console.log("joininto statechanges", this.state.join_into_conversation_fromID, this.props.user.user_id);
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).join_into_conversation_fromID = this.props.user.user_id;
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).join_into_conversation_assignedID = this.props.user.user_id;
		} catch (e) { }
		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).join_into_conversation = 1;
		} catch (e) { }
		try {
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).join_into_conversation = 1;
		} catch (e) { }


		try {
			this.state.chat.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }
		try {
			this.state.chatLeftList.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }

		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).hippaOn = "none";
		} catch (e) { }
		try {
			this.state.chatLeftList.find(
				(v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
			).leftBarClass = "kt-notification-v2__item";
		} catch (e) { }
		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.leftBarClass == "kt-notification-v2__item mh-chatLeftBarActive"
			).leftBarClass = "kt-notification-v2__item";
		} catch (e) { }
		try {
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).leftBarClass = "kt-notification-v2__item mh-chatLeftBarActive";
		} catch (e) { }
		// console.log("joininto statechanges 2", this.state.join_into_conversation_fromID, this.props.user.user_id);
		this.setState({
			chatRepondedTimeWise: this.state.chatRepondedTimeWise,
			waittime: this.state.waittime,
			join_into_conversation_fromID: this.props.user.user_id,
			join_into_conversation_assignedID: this.props.user.user_id,
			join_into_conversation: 1,
			chat: this.state.chat,
			chatLeftList: this.state.chatLeftList,
			tmpVarVal: tmpVarVal,
			LeftSideAccordionWaitActive: false,
			LeftSideAccordionCurrentlyChattingActive: true,
			closeConversation: "n"
		});

		// WEB HOOK
		this.sendLiveChatDataForWebhook(
			anonymous_user_id,
			chatBot_id,
			comment_text
		);
		// WEB HOOK
	};

	isUrl = (s) => {
		var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
		return regexp.test(s);
	};

	urlify = (text) => {
		var urlRegex = /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“�?‘’]))/g;
		return text.replace(urlRegex, function (url) {
			if (this.isUrl(url)) {
				url1 = url;
			} else {
				var url1 = "http://" + url;
			}
			return "<a href='" + url1 + "' target='_blank'>" + url + "</a>";
		});
		return text;
	};

	sendLiveChatDataForWebhook(anonymous_user_id, chatBot_id, text) {
		api.liveChat
			.sendDataToWebhookLiveChat({
				isLiveChat: "ON",
				anonymous_user_id: anonymous_user_id,
				chatBot_id: chatBot_id,
				welcome_msg: "",
				msg_from: "AGENT",
				origin_from: "WEB",
				msg: text,
			})
			.then((resp) => { });
	}

	sendAgent = (anonymous_user_id, comment_text) => {
		// console.log("joininto sendagent", anonymous_user_id, comment_text);
		var company = this.props.user.company;
		var chatBot_id = this.props.user.chatBot_id;
		var chat_logo = this.props.user.chat_logo;
		var primary_color = this.props.user.primary_color;
		comment_text = this.urlify(comment_text);
		comment_text = comment_text.trim();

    
			comment_text = comment_text.replace(/(?:\r\n|\r|\n)/g, "<br>");
      if(comment_text.length>0){
			var liveChatObject = {};
			liveChatObject.anonymous_user_id = anonymous_user_id;
			liveChatObject.name = this.state.name.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).name;
			liveChatObject.chatBot_id = chatBot_id;
			var d = new Date();
			var dateUTC = d.toUTCString();
			var dateUTCUnixTimeStamp = new Date(dateUTC).getTime() / 1000;
			var liveChatMessage = {
				From: "a",
				msgVal: comment_text,
				time: dateUTCUnixTimeStamp,
			};
			liveChatObject.message = liveChatMessage;
			liveChatObject.button_color = primary_color;
			liveChatObject.chat_logo = chat_logo;
			liveChatObject.company = company;
			liveChatObject.liveusercreatedon = dateUTCUnixTimeStamp;
        if (comment_text.length > 0) {
			this.props.socket.emit("live_chat_from_agent added", liveChatObject);
        }
			var tmpVarVal =
				Math.floor(Math.random() * 100000000 + 1) +
				"_" +
				Math.round(new Date().getTime() / 1000);
			liveChatObject.tmpVarVal = tmpVarVal;
			this.props.socket.emit(
				"live_chat_from_agent_again added",
				liveChatObject
			);
			this.addChatData(anonymous_user_id, liveChatObject);
			this.state.waittime.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).time = "0";
			this.state.waittime.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).displayTime = "00 : 00 : 00 Sec";
			this.state.waittime.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).actualDateAndTime = "" + dateUTCUnixTimeStamp + "";
			this.state.chatRepondedTimeWise.find(
				(v) => v.anonymous_user_id == anonymous_user_id
			).lastRespondedAt = "" + dateUTCUnixTimeStamp + "";
			this.state.chatRepondedTimeWise.sort((a, b) =>
				Number(a.lastRespondedAt) < Number(b.lastRespondedAt) ? 1 : -1
			);
			this.setState({
				waittime: this.state.waittime,
				chatRepondedTimeWise: this.state.chatRepondedTimeWise,
				tmpVarVal: tmpVarVal,
			});

			//  // WEB HOOK
			this.sendLiveChatDataForWebhook(
				anonymous_user_id,
				chatBot_id,
				comment_text
			);
			//  // WEB HOOK END

			// User Chat Log
			this.props.socket.emit("live_chat_log");
		} else {
		}
	};

	showModel = (e) => {
		this.setState({
			show: true,
		});
	};

	addQuickText = (e) => {
		this.child.child.addQuickText();
	};

	deleteAllQuickText = (e) => {
		this.child.child.deleteAllQuickText();
	};

	toggleModal = (e) => {
		this.child.hideToaster();
		this.setState({
			show: false,
		});
	};

	setQuickTextForLiveChat = () => {
		api.liveChat
			.getQuickListForLiveChat({})
			.then((resp) => {
				if (resp.data && resp.data.data.list) {
					var obj = { key: "", value: "addNew", text: this.setAddOption() };
					resp.data.data.list.unshift(obj);
					this.setState({
						quickTextOption: resp.data.data.list,
					});
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	NotificationMemberList = () => {
		api.liveChat
			.NotificationMemberList({})
			.then((resp) => {
				if (resp.data && resp.data.data.subAccountlist) {
					var list = resp.data.data.subAccountlist;
					this.setState({
						NotificationMemberListOption: list,
						NotificationMemberSelectVal: list[0].value,
					});
				}
			})
			.catch((e) => {
				//console.log(e);
			});
	};

	setAddOption = (e) => {
		return <div onClick={() => this.openAddOption()}>Click to Add Option </div>;
	};

	openAddOption = (e) => {
		this.showModel();
	};

	QuickSelectChange = (value, anonymousUserId) => {
		if (value == "addNew") {
		} else {
			this.setState({
				quickSelectVal: value,
			});
			document.getElementById(anonymousUserId).value = value;
			document.getElementById(anonymousUserId).focus();
		}
	};

	displayTypingLoader = (anonymousUserId) => {
		return anonymousUserId == this.state;
	};

	quickTextAddEditDelete = (e) => {
		this.setQuickTextForLiveChat();
	};

	NotificationMemberChange = (e) => {
		var DialogBoxConfirmation = {
			show: true,
			modaltitle: "Confirmation!",
			message:
				"Are you sure you want to transfer the chat to the <span class='mh-text-bold'>" +
				e.split("@@_@@")[3] +
				"</span>? You can still do the chat untill <span class='mh-text-bold'>" +
				e.split("@@_@@")[3] +
				"</span> jumps in to the conversation.",
			value: e,
		};
		this.setState({
			DialogBoxConfirmation: DialogBoxConfirmation,
		});
	};

	onDialogBoxConfirmationTransfer = (value) => {
		var DialogBoxConfirmation = {
			show: false,
			modaltitle: "!",
			message: "",
			value: "",
		};
		this.setState({
			DialogBoxConfirmation: DialogBoxConfirmation,
		});

		api.liveChat
			.transferTheChat({
				value: value,
				chatKey: this.props.user.chatBot_id,
				anonymous_user_id: this.state.anonymous_user_id,
			})
			.then((resp) => { })
			.catch((e) => {
				console.log(e);
			});
	};

	onDialogBoxConfirmationMarkAsClosed = (value) => {
		var DialogBoxConfirmation = {
			show: false,
			modaltitle: "!",
			message: "",
			value: "",
		};
		this.setState({
			DialogBoxConfirmation: DialogBoxConfirmation,
			loading: true
		});


		api.liveChat
			.sendChatTranscript({
				chatBot_id: this.props.user.chatBot_id,
				anonymous_user_id: this.state.anonymous_user_id,
			})
			.then((resp) => {
				this.state.chat.find(
					(v) => v.anonymous_user_id == this.state.anonymous_user_id
				).closeConversation = 'y';

				var DialogBoxConfirmation = {
					show: true,
					modaltitle: "Alert!",
					yesOn: "none",
					message:
						"Conversation has been closed now.",
					value: ""
				};
				this.setState({
					chat: this.state.chat,
					DialogBoxConfirmation: DialogBoxConfirmation,
					loading: false,
					closeConversation: "y"

				});

				var liveChatObject = {};
				liveChatObject.anonymous_user_id = this.state.anonymous_user_id;
				this.props.socket.emit("live_chat_conversation closed", liveChatObject);


			})
			.catch((e) => {
				console.log(e);
			});
	};

	DialogBoxConfirmationClose = (e) => {
		var DialogBoxConfirmation = {
			show: false,
			modaltitle: "!",
			message: "",
		};
		this.setState({
			DialogBoxConfirmation: DialogBoxConfirmation,
		});
	};

	displayMarkAsClosed = (join_into_conversation, join_into_conversation_fromID, closeConversation, anonymous_user_id) => {
		return (
			(join_into_conversation == 1 && (join_into_conversation_fromID == this.props.user.user_id) && closeConversation == "n") ?
				<button
					to="#"
					onClick={() => this.markAsClosed(anonymous_user_id)}
					className="btn linear-gradient btn-sm mark-as-closed"
				>
					Mark as closed
				</button> : ""
		)
	}

	markAsClosed = (anonymous_user_id) => {
		var DialogBoxConfirmation = {
			show: true,
			modaltitle: "Confirm your action",
			yesOn: "block",
			message:
				"Are you sure you want to close this conversation?",
			value: ""
		};
		this.setState({
			DialogBoxConfirmation: DialogBoxConfirmation,
		});
	}

	renderWholeChatContainer() {
		const { chat } = this.state;
		// alert(this.state.liveChatTab);
		let tabID = this.state.liveChatTab;
		let fromID = 0;
		if (tabID === 'sms') {
			fromID = 1;
		}
		let chatFilterForTab = chat.filter((item) => {
			if (item.from == tabID || item.from == fromID) {
				return true;
			}else{
				return false;
			}
		});
		
		// console.log("chatFilterForTab", chatFilterForTab, fromID, tabID);
		return chatFilterForTab.map(
			(
				{
					anonymous_user_id,
					button_color,
					chatBot_id,
					chat_logo,
					cahtversion,
					company,
					from,
					liveusercreatedon,
					message,
					name,
					username,
					welcomeMsg,
					displayChatContainer,
					displayWholeChatContainer,
					join_into_conversation,
					join_into_conversation_fromID,
					hippaOn,
					closeConversation
				},
				idx
			) => (
				<div
					key={anonymous_user_id}
					className="kt-portlet"
					style={{ boxShadow: "none", display: displayWholeChatContainer, marginBottom: 0 }}
				>
          <div className="kt-portlet__head chat-header align-items-center">
						<div className="kt-portlet__head-label">
							<h3 className="kt-portlet__head-title">
								<small>{(closeConversation == "y") ? "CLOSED" : (join_into_conversation == 1) ? "IN PROGRESS" : "WAITING"}</small>
							</h3>
						</div>
						<div className="kt-portlet__head-label">
							<button onClick={() => this.showModel()}
								class="btn btn-sm mark-as-closed">Quick response</button>

						</div>
						<div className="kt-portlet__head-label">
							<button onClick={() =>
								this.openSoundNotificationSetting()
							} class="btn btn-sm mark-as-closed">Sound notification settings</button>

						</div>


						<div className="kt-portlet__head-toolbar">
							<div className="kt-portlet__head-actions">
								<button className="btn  btn-sm assignedto">
									<NotificationMemberList
										state={this.state}
										user_id={this.props.user.user_id}
										NotificationMemberChange={this.NotificationMemberChange}
										anonymous_user_id={anonymous_user_id}
									/>
								</button>
								{this.displayMarkAsClosed(join_into_conversation, join_into_conversation_fromID, closeConversation, anonymous_user_id)}
							</div>
						</div>
            <button className="btn fw-bold font-weight-bold" style={{backgroundImage: 'linear-gradient(45deg, #087ab6, #00d39e)',color: '#fff'}} onClick={e => this.showDeleteConfimationModal(e,anonymous_user_id)}>Remove Chat</button>
					</div>
					<div id="chatBody" style={{ minHeight: 382 }}>
						<div style={{ display: displayChatContainer }}>
							{this.renderChat(anonymous_user_id)}
						</div>
					</div>
					<div className="yr-right-footer">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6" style={{ height: 29 }}>
								<img
									id={"loader-" + anonymous_user_id}
									style={{ display: "none" }}
									className="mh-TypingLoaderImg"
									src={this.state.assetPath + "/layouts/layout2/img/loader.gif"}
								/>
							</div>
							<div
								className="col-lg-6 col-md-6 col-sm-6"
								style={{ textAlign: "right" }}
							>
								<QuickSelect
									state={this.state}
									user_id={this.props.user.user_id}
									QuickSelectChange={this.QuickSelectChange}
									anonymous_user_id={anonymous_user_id}
								/>
							</div>
						</div>
					</div>
				</div>
			)
		);
	}

	logoUrl(logoUrl) {
		return this.props.user.logoUrl != "" ? (
			<img src={this.props.user.logoUrl} />
		) : (
			""
		);
	}
	chatLogo(chat_logo, button_color) {
		return chat_logo != "" ? (
			<div className="kt-widget3__user-img chat-img">
				<img
					className="kt-widget3__img mh-companyChatLogo"
					src={this.state.assetPath + "/images/chatlogo/" + chat_logo}
					alt=""
				/>
			</div>
		) : (
			<div
				className="kt-widget3__user-img chat-img"
				style={{ backgroundColor: button_color }}
			>
				<img
					className="kt-widget3__img mh-defaultChatLogo"
					src={this.state.assetPath + "/layouts/layout2/img/zyra-tp-logo.png"}
					alt=""
				/>
			</div>
		);
	}
	renderChat(anonymousUserId) {
		const { chat } = this.state;
		return chat.map(
			(
				{
					anonymous_user_id,
					button_color,
					chatBot_id,
					chat_logo,
					cahtversion,
					company,
					from,
					liveusercreatedon,
					message,
					name,
					username,
					welcomeMsg,
					displayChatContainer,
					hippaOn,
				},
				idx
			) =>
				anonymous_user_id == anonymousUserId ? (
					hippaOn == "block" ? (
						<div key={anonymous_user_id} className="hippaBox" style={{ display: hippaOn }}>
							<div className="yr-openMsg sensitive-chat-info-box">
								<div className="yr-welcome-box">
									<span>
										<div className="yr-awatarImg">
											{this.logoUrl(this.props.user.logoUrl)}
										</div>
										<p style={{ fontWeight: 600, fontSize: 15 }}>
											Please{" "}
											<a
												href="javascript:void(0);"
												onClick={() => this.openHipaaBox(anonymous_user_id)}
											>
												Click here
											</a>{" "}
											to see the sensitive chat information
										</p>
									</span>
								</div>
							</div>
						</div>
					) : (
						<div key={anonymous_user_id} style={{ display: displayChatContainer }}>
							<ScrollToBottom
								checkInterval={10}
								followButtonClassName={anonymous_user_id}
								scrollViewClassName="kt-portlet__body"
								className={"kt-portlet__body mh-oneChatContainer " + anonymous_user_id}
								id="mh-oneChatContainer"
								ref={this.myRef}
							>
								<div className="kt-widget3">
									<div className="kt-widget3__item left-item">
										<div className="kt-widget3__header">
											{this.chatLogo(chat_logo, button_color)}
											<div className="kt-widget3__info">
												<span className="kt-widget3__username">{company}</span>
												<span className="kt-widget3__time ml-2">
													{this.dateTime(message.time)}
												</span>
											</div>
										</div>
										<div className="kt-widget3__body highlited-body">
											<p className="kt-widget3__text">
												{ReactHtmlParser(welcomeMsg.replace(/%25/g, "%"))}
											</p>
										</div>
									</div>
								</div>
								<div className="kt-widget3">
									<div className="kt-widget3__item right-item">
										<div className="kt-widget3__header">
											<div className="kt-widget3__user-img chat-img">
												<img
													className="kt-widget3__img"
													style={{ height: "42px" }}
													src={
														this.state.assetPath +
														"/images/chatlogo/visitor-logo.png"
													}
													alt=""
												/>
											</div>
											<div className="kt-widget3__info">
												<span className="kt-widget3__username">
													{this.renderName(anonymous_user_id, name)}
												</span>
												<span className="kt-widget3__time ml-2">
													{this.dateTime(message.time)}
												</span>
											</div>
										</div>
										<div className="kt-widget3__body">
                      <p className="kt-widget3__text">
                        <div dangerouslySetInnerHTML={{__html: message.msgVal}}></div>
                      </p>
										</div>
									</div>
								</div>
								{this.renderRemainChat(anonymous_user_id)}
							</ScrollToBottom>
						</div>
					)
				) : (
					""
				)
		);
	}

	renderRemainChat(anonymousUserId) {
		const { chatRemain } = this.state;
		var anonymous_user_id,
			button_color,
			chatBot_id,
			chat_logo,
			cahtversion,
			company,
			from,
			liveusercreatedon,
			message,
			name,
			username,
			welcomeMsg = "";
		return chatRemain[anonymousUserId] === undefined
			? ""
			: chatRemain[anonymousUserId].map(
				(
					{
						anonymous_user_id,
						button_color,
						chatBot_id,
						chat_logo,
						cahtversion,
						company,
						from,
						liveusercreatedon,
						message,
						name,
						username,
						welcomeMsg,
					},
					idx
				) =>
          message.From == "a" ? ( message.msgVal !== '' &&
						<div className="kt-widget3">
							<div className="kt-widget3__item left-item">
								<div className="kt-widget3__header">
									{this.chatLogo(chat_logo, button_color)}
									<div className="kt-widget3__info">
										<span className="kt-widget3__username">{company}</span>
										<span className="kt-widget3__time ml-2">
											{this.dateTime(message.time)}
										</span>
									</div>
								</div>
								<div className="kt-widget3__body highlited-body">
									<p className="kt-widget3__text">
										{ReactHtmlParser(message.msgVal)}
									</p>
								</div>
							</div>
						</div>
					) : (
						('disconnected' in message) ?
							<div className={"kt-widget3 mh-connDisconnLeaveMsg mh-connDisconnLeaveMsg-type-" + message.disconnected}>
								<span class={"mh-connDisconnLeaveMsg-span mh-connDisconnLeaveMsg-span-type-" + message.disconnected}>
									{this.dateTime(message.time) + " - " + message.msgVal}
								</span>
							</div> :
							<div className="kt-widget3">
								<div className="kt-widget3__item right-item">
									<div className="kt-widget3__header">
										<div className="kt-widget3__user-img chat-img">
											<img
												className="kt-widget3__img"
												style={{ height: "42px" }}
												src={
													this.state.assetPath +
													"/images/chatlogo/visitor-logo.png"
												}
												alt=""
											/>
										</div>
										<div className="kt-widget3__info">
											<span className="kt-widget3__username">
												{this.renderName(anonymous_user_id, name)}
											</span>
											<span className="kt-widget3__time ml-2">
												{this.dateTime(message.time)}
											</span>
										</div>
									</div>
									<div className="kt-widget3__body">
                    <p className="kt-widget3__text">
                    <div dangerouslySetInnerHTML={{__html: message.msgVal}}></div>
                    </p>
									</div>
								</div>
							</div>

					)
			);
	}

	openLeftAccordion = (e) => {
		if (e == "w") {
			var w = true;
			if (this.state.LeftSideAccordionWaitActive == true) w = false;

			this.setState({
				LeftSideAccordionWaitActive: w,
				LeftSideAccordionCurrentlyChattingActive: false,
			});
		} else {
			var cc = true;
			if (this.state.LeftSideAccordionCurrentlyChattingActive == true)
				cc = false;
			this.setState({
				LeftSideAccordionWaitActive: false,
				LeftSideAccordionCurrentlyChattingActive: cc,
			});
		}
	};

	onValidSubmitSoundNotification = (which, value) => {
		this.state.soundNotiAndSettingmodalData[which] = value;
		if (which == "sound_notification") {
			if (value == "1")
				this.state.soundNotiAndSettingmodalData[
					"sound_notificationChecked"
				] = true;
			else
				this.state.soundNotiAndSettingmodalData[
					"sound_notificationChecked"
				] = false;
		} else {
			if (value == "1")
				this.state.soundNotiAndSettingmodalData[
					"first_time_sound_notificationChecked"
				] = true;
			else
				this.state.soundNotiAndSettingmodalData[
					"first_time_sound_notificationChecked"
				] = false;
		}

		this.setState({
			soundNotiAndSettingmodalData: this.state.soundNotiAndSettingmodalData,
		});

		// console.log(this.state);
	};

	openSoundNotificationSetting = () => {
		this.setState({
			showSoundNotiDialog: true,
			modalstatusNotiAandMisc: true,
		});
	};

	soundSettingModalClose = (e) => {
		this.setState({
			showSoundNotiDialog: false,
			modalstatusNotiAandMisc: false,
		});
	};
	// alert(which)
	// alert(value)

	handleTextSmscontent = (e) => {
		this.setState({
			smsTextContent: e.target.value
		})
	}
	handleClick = () => {
		this.setState({
			loadingSendText: true,
		});
		api.liveChat.listSmsNumber({ type: 'livechat' })
			.finally(() => {
				this.setState({
					loadingSendText: false,
				});
			})
			.then((data) => {
				if (data.status && data.message === 'Success') {
					let phoneList = data.data.map((itm) => {
						return {
							key: itm.auto_id,
							value: itm.phone,
							text: generateMask(itm.phone)
						}
					});
					this.setState({
						showModelSendText: true,
						sendTextModalLoading: true,
						trumiaPhone: phoneList
					})
					const getPhone = api.liveChat.getChatListPhoneNumbers({ index: 1 });
					getPhone.then((data) => {
						// console.log(data.data);
						if (data.data.status) {
							const dataList = data.data.data;
							const newobj = dataList.map((item) => {
								return {
									value: item.phone_number,
									checked: false,
									label: generateMask(item.phone_number),
									id: uid()
								}
							});
							this.setState({ allListNumber: newobj });
						}
					}).catch((err) => {
						console.log(err);
					})
					this.setState({
						sendTextModalLoading: false,
					});
				} else {
					this.setState({
						trumpiaNumberNotFound: true,
					});
					// setTimeout(() => {
					// 	this.setState({
					// 		trumpiaNumberNotFound: false,
					// 	});
					// }, 3000);
				}
				// console.log('list sms number', data);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	handleTitalClick = (e, itemProps) => {
		const { index } = itemProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index

		this.setState({ activeIndex: newIndex })
	}
	modalFooterSendText = (type = null) => {
		return (
			<div className="modal-footer">
				<button
					type="button"
					onClick={() => this.modalCloseSendText('sendform')}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
				>
					<span>Close</span>
				</button>
				<button
					type="button"
					onClick={this.saveSendSms}
					className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
					disabled={this.state.smsbtndisabled}
				>
					<span>Save</span>
				</button>
			</div>
		);

	}
	modalCloseSendText = (type = '') => {
		if (type === 'sendform') {
			this.fethSmsListData();
		}
		this.setState({
			showModelSendText: false,
			fromNumber: '',
			trumiaPhone: null,
			allListNumber: null,
			selectedOption: [],
			selectedFile: null,
			csvdata: null,
			uploadCsvBox: false,
			csvError: false,
			csvDisabled: true,
			customInput: [],
			shortnerBox: false,
			validityinhour: '',
			smsTextContent: '',
			textContentError: false,
			uploadtemplate: '',
			errorsMmsUpload: '',
			acceptTerms: false,
			termAcceptError: false,
			team: [],
			customInputError: false,
			recipientError: false,
			validityinhourError: false,
			fromNumberError: false,
			responsemsg: '',
			smsbtndisabled: false
		});
	}

	saveSendSms = async () => {
		let textContentError = false;
		let recipientError = false;
		let errorCountCustom = false;
		let validityinhour = false;
		let fromNumberError = false;
		// check from number
		if (!this.state.fromNumber) {
			fromNumberError = true;
			this.setState({
				fromNumberError: true
			});
			return
		} else {
			fromNumberError = false;
			this.setState({
				fromNumberError: false
			});
		}
		// check input reciepient list
		if ((this.state.team.length === 0) && (this.state.csvdata === null) && (this.state.customInput.length === 0)) {
			recipientError = true;
		} else {
			recipientError = false;
		}
		if (recipientError) {
			this.setState({
				recipientError: true
			});
			return;
		} else {
			this.setState({
				recipientError: false
			});
		}
		if (this.state.customInput?.length > 0) {
			this.validateCustomInput(this.state.customInput, (errorCount, newStateData) => {
				// console.log('in callback', errorCount, newStateData);

				this.setState({
					customInputError: errorCount > 0 ? true : false,
					customInput: newStateData
				})
				if (errorCount > 0) {
					errorCountCustom = true;
					return;
				} else {
					errorCountCustom = false;
				}
			});
		}
		// check upload template and text message content
		if (this.state.uploadtemplate === '' && this.state.smsTextContent === '') {
			textContentError = true
		} else if (this.state.smsTextContent.trim().length > 16000) {
			textContentError = true
		} else {
			textContentError = false
		}
		if (textContentError) {
			this.setState({
				textContentError: true
			});
			return;
		} else {
			this.setState({
				textContentError: false
			});
		}
		// check accept term and condition
		if (!this.state.acceptTerms) {
			this.setState({
				termAcceptError: true
			})
			return;
		}

		// if (!this.state.validityinhour) {
		// 	validityinhour = true;
		// 	this.setState({
		// 		validityinhourError: true
		// 	});
		// 	return
		// } else {
		// 	validityinhour = false;
		// 	this.setState({
		// 		validityinhourError: false
		// 	});
		// }



		if (!textContentError && !recipientError && !errorCountCustom && !this.state.termAcceptError && !this.state.errorsMmsUpload && !fromNumberError) {
			// console.log('No error found');
			this.setState({
				smsbtndisabled: true,
				sendTextModalLoading: true
			})
			const formData = new FormData();
			this.getImageUrl(this, (img) => {

				formData.append('mmsContent', img);
				formData.append('mmsContentName', this.state.uploadtemplate ? this.state.uploadtemplate.name : '');
				formData.append('fromMessage', this.state.fromNumber);
				// formData.append('validity', this.state.validityinhour);
				formData.append('inputCustom', this.state.customInput.map((data) => data.value?.replace(/[^\d]*/gi, "")));
				formData.append('csvInput', this.state.csvdata);
				formData.append('listInput', this.state.team.map((data) => data.value));
				formData.append('textContent', this.state.smsTextContent);
				const config = {
					headers: {
						'content-type': 'multipart/form-data',
					},
				};
				api.liveChat.saveSendTextMessage(formData, config)
					.finally(() => {
						const modalDiv = document.querySelector('.completedchats');
						// console.log(modalDiv);
						modalDiv.scrollTop = 0;
						const formDataClear = document.getElementById('sendtextform');
						formDataClear.reset();
						this.setState({
							smsbtndisabled: false,
							sendTextModalLoading: false
						});
					})
					.then((data) => {
						// console.log(data.data);
						if (data.data.status) {
							this.setState({
								responsemsg: data.data.message,
								fromNumber: '',
								selectedOption: [],
								selectedFile: null,
								csvdata: null,
								customInput: [],
								validityinhour: '',
								smsTextContent: '',
								textContentError: false,
								uploadtemplate: '',
								errorsMmsUpload: '',
								acceptTerms: false,
								termAcceptError: false,
								team: [],
								customInputError: false,
								recipientError: false,
								validityinhourError: false,
								fromNumberError: false,
							});
							api.liveChat.sendTextLiveTrumpia();
						} else {
							this.setState({
								responsemsg: data.message
							});
						}
						setTimeout(() => {
							this.setState({
								responsemsg: ''
							});
						}, 3000);
					}).catch((err) => {
						console.log(err);
					})

			})
		} else {
			console.log('error found');
		}
	}


	getImageUrl = (obj, callback) => {
		if (obj.state.uploadtemplate !== '') {
			//console.log(obj.state.uploadtemplate)
			var reader = new FileReader();
			reader.readAsDataURL(obj.state.uploadtemplate);
			reader.onloadend = function () {
				var base64data = reader.result;
				callback(base64data);
			}
		} else {
			callback('');
		}
	}

	validateCustomInput = (stateData, callbackValidate) => {
		let error = 0;
		const newStateData = stateData.map((item, index) => {
			let inputError = false;
			if (!this.formatPhoneNumber(item.value)) {
				inputError = true;
				error++;
			}
			return {
				value: item.value,
				id: item.id,
				currentFocus: false,
				inputError: inputError
			}
		});
		// console.log(error, newStateData);
		callbackValidate(error, newStateData);
	}

	selectFromNumber = (e) => {
		this.setState({ fromNumber: e.target.value })
	}

	renderOptionPopup = () => {
		const phoneList = this.state.trumiaPhone;
		return phoneList && phoneList.map((key) => {
			return <option key={key.value} value={key.value}>{key.text}</option>
		})
	}
	removeCustomInput = (id) => {
		let allData = this.state.customInput;
		let filteredData = allData.filter((item) => item.id !== id);
		// console.log("filter", filteredData);
		this.setState({ customInput: filteredData })
	}

	handleCustomInput = (e) => {
		const inputId = e.target.name.split('_');
		const numberValue = phoneFormat(e);
		let allData = [...this.state.customInput];
		let setFalse = allData.map((itm) => {
			return {
				value: itm.value,
				id: itm.id,
				currentFocus: false,
				inputError: false
			}
		});
		let filteredData = setFalse.findIndex((item) => item.id === inputId[1]);
		setFalse[filteredData].value = numberValue;
		setFalse[filteredData].currentFocus = true;
		// console.log(setFalse);
		this.setState({ customInput: setFalse })
	}

	viewRecipient = (e) => {
		e.preventDefault();
		this.setState({
			showModelRecipient: true
		})
	}

	closeViewRecipientModel = () => {
		this.setState({
			showModelRecipient: false
		})
	}

	viewRecipientBody = () => {
		return (
			<div className={`row ${classes.viewRecipientBody}`}>
				<div className="col-md-4">
					<div className={classes.list_dataheading}>Number list</div>
					<div className={classes.list_view}>
						{this.state?.team && this.state?.team.map((data) => {
							return <span key={data.id}>{data.label}</span>
						})}
					</div>
				</div>
				<div className="col-md-4">
					<div className={classes.list_dataheading}>Csv data</div>
					<div className={classes.list_view}>
						{this.state.csvdata && this.state.csvdata.map((data, i) => {
							return <span key={i}>{data}</span>
						})}
					</div>
				</div>
				<div className="col-md-4">
					<div className={classes.list_dataheading}>Custom input</div>
					<div className={classes.list_view}>
						{this.state.customInput && this.state.customInput.map((data, ind) => {
							return <span key={ind}>{data.value}</span>
						})}
					</div>
				</div>
			</div>
		)
	}

	modalBodySendText = () => {
		return (
			<div className='row'>
				<div className='col-md-12'>
					<Message
						color="green"
						style={{
							display: this.state.responsemsg ? "block" : "none",
							height: "44px"
						}}
					>
						{this.state.responsemsg}
					</Message>

					<Form id="sendtextform">
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field>
								<label>From</label>
								{this.state.trumiaPhone &&
									<div className="yr-selectArrow">
										<select className="form-control" name='fromnumber' value={this.state.fromNumber} onChange={this.selectFromNumber}>
											<option key="1" value="">Select</option>
											{this.renderOptionPopup()}
										</select>
									</div>
								}
							</Form.Field>
							{this.state.fromNumberError && <p className='text-danger'>Please select from number.</p>}
						</div>
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field>
								<label>To</label>
								<div style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-around',
									// flexWrap: 'wrap'
								}}>
									<div className={classes.chatlist_number}>
										{this.state.allListNumber &&
											<ReactMultiSelectCheckboxes
												options={this.state.allListNumber}
												setState={this.state.allListNumber}
												onChange={this.teamChange}
												key={'multiselect'}
											/>
										}
									</div>
									<Button handleClick={this.uploadCsvOpen} className={classes.liveChat_formbutton}>Upload CSV</Button>
									<Button className={classes.liveChat_formbutton} handleClick={this.addCustomInput}>Add Custom</Button>
								</div>
							</Form.Field>
						</div>
						{this.state.uploadCsvBox &&
							<Segment>
								<Dimmer active={this.state.uploadCsvLoading}>
									<Loader indeterminate>Loading...</Loader>
								</Dimmer>
								<div className={`col-md-12 ${classes.livechatformdiv}`}>
									<div className={classes.urlshortner__box}>
										<input
											type='file'
											name='file'
											onChange={this.onFileChange}
											pattern="^.+\.(xlsx|xls|csv)$"
											accept='.csv'
											id="csvinputid"
										/>
										<a download href='/assets/example.csv'>Download our sample CSV File.</a>
										<Popup
											position="top right"
											size="small"
											content={"Please upload your file as given sample csv file. Invalid phone number will be removed."}
											trigger={<Icon name="info" size="small" circular />}
											style={{ margin: 8 }}
										/>
										{this.state.csvError && <span style={{ color: 'red', marginLeft: 10 }}>{this.state.csvError}</span>}
										{this.state.csvdata &&
											<p>{this.state.selectedFile.name}</p>
										}
									</div>
									<div className={classes.csvupload_buttons}>
										<button
											type="button"
											onClick={this.uploadCsvOpen}
											className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
											data-dismiss="modal"
										>
											<span>Close</span>
										</button>
										{this.state.csvdata &&
											<button
												type="button"
												onClick={this.deleteSelectedCsv}
												className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
												data-dismiss="modal"
											>
												<span>Delete</span>
											</button>
										}
										<button
											type="button"
											onClick={this.onUploadFile}
											className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
											disabled={this.state.csvDisabled}
										>
											<span>Save</span>
										</button>
									</div>
								</div>
							</Segment>
						}
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field>
								<label className={classes.livechatform_viewrecipient}>
									<a href='#' onClick={this.viewRecipient}>
										<i className='fa fa-eye' aria-hidden='true'></i>
										<span>View selected recipient's</span>
									</a>
								</label>

							</Form.Field>
							{this.state.recipientError && <p className='text-danger'>Please select or add at least one recipient number.</p>}
							{this.state.customInputError && <p className='text-danger'>Please provide valid phone number in cutsom input field.</p>}
						</div>
						{this.state.customInput &&
							<div className={`col-md-12 ${classes.livechatformdiv}`}>
								{this.state.customInput.map((item) => (
									<InputNumber
										itemData={item}
										key={item.id}
										addCustomInput={this.addCustomInput}
										removeCustomInput={this.removeCustomInput}
										handleCustomInput={this.handleCustomInput}
									/>
								))}
							</div>
						}
						{/* <div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field>
								<label>Validity in Hours</label>
								<div className="yr-selectArrow">
									<select className="form-control" name='validityinhour' value={this.state.validityinhour} onChange={this.selectValidity}>
										<option key={"0"} value="">Select</option>
										{renderValidity()}
									</select>
								</div>
							</Form.Field>
							{this.state.validityinhourError && <p className='text-danger'>Please select validity.</p>}
						</div> */}
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<p style={{ fontWeight: 500 }}>If you are sending a link, please don't use rebrand.ly , bit.ly or any other third party url shortener. This may cause some issue in SMS delivery. <a href='#' onClick={this.handleOnCloseShortner}>Click here</a> to create your own url short link.</p>
						</div>
						{this.state.shortnerBox &&
							<div className={`col-md-12 ${classes.livechatformdiv}`}>
								<UrlShortner
									OnCloseShortner={this.handleOnCloseShortner}
								/>
							</div>
						}
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field>
								<label>Message</label>
								<textarea key='smstext' placeholder='Message' value={this.state.smsTextContent} onChange={this.handleTextSmscontent} rows={3} />
							</Form.Field>
							{this.state.textContentError && <p className='text-danger'>Write some text or choose an image.</p>}
						</div>
						<div className="form-group row">
							<label className="col-lg-4">Upload file :</label>
							<div className="col-lg-4">
								<div className="field">
									<div className="ui fluid input" style={{ display: 'inline' }}>
										<div class="logo-img" style={{ width: '33%', height: 150, backgroundColor: 'transparent' }}><img src={this.state.uploadtemplate == '' ? "assets/media/logos/upload-bg.png" : URL.createObjectURL(this.state.uploadtemplate)} alt="" /></div>
										{this.state.errorsMmsUpload != '' && (
											<div style={{ color: 'red' }}>{this.state.errorsMmsUpload}</div>
										)}
									</div>
								</div>
							</div>
							<label className="col-lg-4">
								<div class="d-flex">
									<div class="upload-btn-wrapper">
										<button class="btn linear-gradient zy-save-btn ml-2">Upload</button>
										<input type="file" name="uploadtemplate" id="uploadtemplateID" onChange={(e) => this.onchangeTemplate(e)} accept="image/jpg,image/png,image/jpeg,image/gif" />
									</div>
									{this.state.uploadtemplate && (<button type="reset" className="btn linear-gradient zy-cancel-btn ml-2" onClick={(e) => this.onremoveTemplate(e)}>Remove</button>)}
								</div>
							</label>
						</div>
						<div className={`col-md-12 ${classes.livechatformdiv}`}>
							<Form.Field className={classes.livechat__checkterm}>
								<Checkbox
									label={''}
									onChange={this.handleTermChange}
									checked={this.state.acceptTerms}
								/>
								<a href='#' onClick={this.openTermsAndCondition}>
									By using our SMS functionality, you agree to our Disclaimer of Liability
								</a>
							</Form.Field>
							{this.state.termAcceptError && <p className='text-danger'>Please accept terms and condition.</p>}
						</div>
					</Form>
				</div>
			</div>
		)
	}

	openTermsAndCondition = (e) => {
		e.preventDefault();
		this.setState({
			commonmodelshow: true,
			commonmodelBody: this.commonmodelBody('termscondition'),
			commonmodelFooter: this.commonmodelFooter('termscondition'),
			commonModelTital: 'Disclaimer of Liability',
			commonModalId: null,
		})
	}

	handleTermChange = (e) => {
		this.setState({
			acceptTerms: !this.state.acceptTerms,
			termAcceptError: false
		});
	}

	onremoveTemplate = (event) => {
		document.getElementById("uploadtemplateID").value = "";
		this.setState({
			uploadtemplate: '',
			errorsMmsUpload: '',
		});
	}

	onchangeTemplate = (event1) => {
		let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp', 'image/pjpeg', 'image/webp', 'image/svg+xml'];
		let mainfile = event1.target.files[0];
		// console.log(mainfile.size);
		let stateObj = this;
		if (mainfile.size < '512001') {
			var _URL = window.URL || window.webkitURL;
			var img = new Image();
			var objectUrl = _URL.createObjectURL(mainfile);
			var event = event1
			img.onload = function (event) {
				_URL.revokeObjectURL(objectUrl);
				if (this.width < 641 && this.height < 1139) {
					// console.log('i am here in width');
					if (imageMIME.includes(mainfile.type) == true && mainfile.type != '') {
						stateObj.setState({
							errorsMmsUpload: '',
						});
						let file = mainfile;
						//let imgurl = URL.createObjectURL(event.target.files[0]);
						//console.log(imgurl);

						stateObj.setState({
							uploadtemplate: file,

						});
						var all = document.getElementsByClassName('postcard--listitem');
						for (var i = 0; i < all.length; i++) {
							all[i].style.pointerEvents = 'none';
						}
					} else {
						stateObj.setState({
							uploadtemplate: '',
							errorsMmsUpload: 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.',
						});
					}
				} else {
					stateObj.setState({
						uploadtemplate: '',
						errorsMmsUpload: 'Image size should not be greater than 640px wide and 1138px tall.',
					});
				}
			};
			img.src = objectUrl;
		} else {
			stateObj.setState({
				uploadtemplate: '',
				errorsMmsUpload: 'Image size should be less than 512 KB.',
			});
		}
	};

	selectValidity = (e) => {
		this.setState({ validityinhour: e.target.value })
	}

	handleOnCloseShortner = (e) => {
		this.setState({ shortnerBox: !this.state.shortnerBox });
	}


	addCustomInput = () => {
		let customInputList = this.state.customInput;
		const inputObj = {
			value: '',
			id: uid(),
			currentFocus: false,
			inputError: false
		};
		const newData = [...customInputList, inputObj]
		// console.log(newData);
		this.setState({
			customInput: newData
		})
	}

	teamChange = (value, event) => {
		this.setState({
			team: value,
		});
	};

	uploadCsvOpen = () => {
		this.setState({
			csvError: '',
			uploadCsvBox: !this.state.uploadCsvBox
		})
	}

	onFileChange = (e) => {
		const allowedType = ['application/vnd.ms-excel', 'text/csv'];
		const fileInput = e.target.files[0];
		// console.log(fileInput, fileInput.type);
		if (allowedType.includes(fileInput.type)) {
			this.setState({
				csvError: '',
				selectedFile: fileInput,
				csvDisabled: false
			})
		} else {
			this.setState({
				csvError: 'Please upload only csv file.',
				selectedFile: null,
				csvdata: null,
			});
		}
	}
	onUploadFile = () => {
		this.setState({
			csvDisabled: true,
			// sendTextModalLoading: true,
			uploadCsvLoading: true
		});
		const file = this.state.selectedFile;
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			// console.log('file loading');
			const fileContent = reader.result;
			// console.log(fileContent);
			const newData = this.CSVToArray(fileContent);
			this.processCsvFile(newData, () => {
				this.setState({
					sendTextModalLoading: false,
					uploadCsvLoading: false
				});
			});
		}
		reader.onerror = () => {
			// console.log('Something went wrong', reader.error);
			this.setState({
				csvError: `Something went wrong, ${reader.error}`
			});
		}
	}
	formatPhoneNumber = (str) => {
		//Filter only numbers from the input
		let cleaned = ('' + str).replace(/\D/g, '');

		//Check if the input is of correct length
		// let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		let match = cleaned.match(/^\(?([2-9][0-9][0-9])\)?[- ]?(\d{3})[- ]?(\d{4})$/);
		if (match) {
			return match[0]
		} else {
			return '';
		}
	};
	CSVToArray = (data, delimiter = ',', omitFirstRow = false) => data
		.slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
		.split('\n')
		.map(v => v.split(delimiter));

	processCsvFile = (csvDataList, cb) => {
		// console.log('csvDataList', csvDataList);
		const nn = csvDataList.map((data) => this.formatPhoneNumber(data[0]));
		const [...removedDuplicate] = new Set(nn);
		const removeEmpty = removedDuplicate.filter((rows) => rows !== '');
		// console.log(removeEmpty);
		const newArrayData = removeEmpty.map((data) => generateMask(data));
		// console.log(newArrayData);
		if (newArrayData.length > 0) {
			this.setState({
				csvdata: newArrayData,
			});
		} else {
			this.setState({
				csvdata: null,
			});
		}
		cb();
	}

	deleteSelectedCsv = () => {
		document.getElementById('csvinputid').value = '';
		this.setState({
			csvError: '',
			selectedFile: null,
			csvdata: null,
		});
	}

	fethSmsListData = () => {
		this.setState({
			_pagelist: 1,
			_pagearchive: 1,
			liveChatTab: 'sms'
		}, () => {
			this.setChatArea('sms');
		})

		this.fethSmsTextData();
		this.fethSmsArchiveData();
	}

	fethSmsTextData = async (activePage = 1, type = '') => {
		this.setState({
			listLoading: true
		})
		let params = { _pagelist: activePage };
		// if (this.state._pagelist != activePage) {
		// 	params = { _pagelist: this.state._pagelist };
		// }

		const newState = Object.assign({}, this.state, params, { loading: false });
		// console.log(newState);
		queryParamsList.forEach(function (element) {
			if (!(element in params)) {
				params[element] = newState[element];
			}
		});
		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;

		params["time_zone"] = time_zone;
		params["status"] = 1;

		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");

		// console.log(query);

		let totalCountQuery = "";
		if (params.qlist !== "") {
			totalCountQuery = `q=${params.qlist}`;
		}
		const textWithNoReply = axios.get(`${config.apiBaseURL}/livechat/liveChat/getSentMessage?${query}`);
		// console.log(textWithNoReply);
		const allDataList = (await textWithNoReply).data;
		let viewButton = false
		if (allDataList.status) {
			if (allDataList.data.totalrecord > (activePage * 10)) {
				viewButton = true
			}
			if (type === 'listview') {
				let data = this.state.allTextSendList;
				let newdata = [...data, ...allDataList.data.list]
				this.setState({
					allTextSendList: newdata,
					listLoading: false,
					allTextSendView: viewButton
				});
			} else {
				this.setState({
					allTextSendList: allDataList.data.list,
					listLoading: false,
					allTextSendView: viewButton
				})
			}
		} else {
			this.setState({ listLoading: false });
		}
	}

	fethSmsArchiveData = async (activePage = 1, type = '') => {
		this.setState({
			archiveLoading: true
		})
		let params = { _pagearchive: activePage };
		// if (this.state._pagelist != activePage) {
		// 	params = { _pagelist: this.state._pagelist };
		// }

		const newState = Object.assign({}, this.state, params, { loading: false });
		// console.log(newState);
		queryParamsArchive.forEach(function (element) {
			if (!(element in params)) {
				params[element] = newState[element];
			}
		});
		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;

		params["time_zone"] = time_zone;
		params["status"] = 1;

		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");

		// console.log(query);

		let totalCountQuery = "";
		if (params.qarchive !== "") {
			totalCountQuery = `q=${params.qarchive}`;
		}
		const archiveListGet = axios.get(`${config.apiBaseURL}/livechat/liveChat/archiveSmsList?${query}`);
		// console.log(archiveListGet);
		const allDataarchive = (await archiveListGet).data;
		let viewButton = false;
		if (allDataarchive.status) {
			if (allDataarchive.data.totalrecord > (activePage * 10)) {
				viewButton = true
			}
			if (type === 'archiveview') {
				let data = this.state.smsArchiveList;
				let newdata = [...data, ...allDataarchive.data.list]
				this.setState({
					smsArchiveList: newdata,
					archiveLoading: false,
					smsArchiveView: viewButton
				});
			} else {
				this.setState({
					smsArchiveList: allDataarchive.data.list,
					archiveLoading: false,
					smsArchiveView: viewButton
				})
			}
		} else {
			this.setState({ archiveLoading: false });
		}
	}

	viewMorelist = (e, type) => {
		e.preventDefault();
		let activePage = this.state._pagelist + 1;
		this.setState(prevState => ({
			_pagelist: prevState._pagelist + 1,
			listLoading: true
		}))
		// console.log(activePage, this.state._pagelist);
		this.fethSmsTextData(activePage, 'listview');
	}

	viewMorearchivelist = (e, type) => {
		e.preventDefault();
		let activePage = this.state._pagearchive + 1;
		this.setState(prevState => ({
			_pagearchive: prevState._pagearchive + 1,
			archiveLoading: true
		}))
		// console.log(activePage, this.state._pagearchive);
		this.fethSmsArchiveData(activePage, 'archiveview');
	}

	archiveChat = (e, id) => {
		e.stopPropagation();
		this.setState({
			commonmodelshow: true,
			commonmodelBody: this.commonmodelBody('archiveChat'),
			commonmodelFooter: this.commonmodelFooter('archiveChat'),
			commonModelTital: 'Archive SMS',
			commonModalId: id,
		})
	}

	handleChangeUserName = (e) => {
		this.setState({
			newUserNameValue: e.target.value
		})
	}

	saveChangeUserName = (id) => {
		if (this.state.newUserNameValue === '') {
			this.setState({
				changeUserNameError: 'This field is required.'
			})
		} else {
			this.setState({
				changeUserNameError: '',
				changeNameLoading: true,
				commonmodelSaveButtonDisabled: true
			});
			api.liveChat.changeName({ id: id, fullname: this.state.newUserNameValue })
				.then((res) => {
					let colorName = 'red';
					if (res.data.status) {
						colorName = 'green';
						let chatData = this.state.chat;
						let chatDataLeft = this.state.chatLeftList;
						let chatRemain = this.state.chatRemain;
						let chatName = this.state.name;
						// change name in name state
						let nameIndex = chatName.findIndex((item, curIndex) => item.anonymous_user_id === id);
						let newchatName = chatName.find((item, curIndex) => item.anonymous_user_id === id);
						newchatName.name = this.state.newUserNameValue;
						chatName.splice(nameIndex, 1, newchatName);

						// change name in chat state
						let chatIndex = chatData.findIndex((item, curIndex) => item.anonymous_user_id === id);
						let newchatdata = chatData.find((item, curIndex) => item.anonymous_user_id === id);
						newchatdata.name = this.state.newUserNameValue;
						chatData.splice(chatIndex, 1, newchatdata);

						// change name in chatLeftList Satte
						let chatLeftIndex = chatData.findIndex((item, curIndex) => item.anonymous_user_id === id);
						let newChatDataLeft = chatDataLeft.find((item, curIndex) => item.anonymous_user_id === id);
						newChatDataLeft.name = this.state.newUserNameValue;
						chatDataLeft.splice(chatLeftIndex, 1, newChatDataLeft);

						// change name in chatRemain state
						if (chatRemain.hasOwnProperty(id)) {
							chatRemain[id].name = this.state.newUserNameValue;
						}

						this.setState({
							changeNameRes: [res.data.message, colorName],
							chat: chatData,
							chatLeftList: chatDataLeft,
							chatRemain: chatRemain,
							name: chatName
						})

						// this.fethSmsListData();
					} else {
						this.setState({
							changeNameRes: [res.data.message, colorName],
						});
					}
					setTimeout(() => {
						this.setState({
							changeNameRes: '',
							newUserNameValue: ''
						});
					}, 5000);
				}).catch((err) => {
					console.log(err);
				}).finally(() => {
					this.setState({
						newUserNameValue: '',
						changeNameLoading: false,
						commonmodelSaveButtonDisabled: false,
						changeNameshow: false,
						changeNameTital: '',
						commonModalId: null,

					});
				})
		}
	}

	commonmodelBody = (type = '', data = '') => {
		if (type === 'archiveChat') {
			return (
				<div>Are you sure you want to archive it?</div>
			)
		} else if (type === 'termscondition') {
			return (
				<TermsAndConditionSms />
			)
		} else if(type === 'viewarchivechat'){
			return (
				<ViewArchiveChat data={data}/>
			)
		}
	}

	changeUserName = (e, id, name) => {
		e.stopPropagation();
		this.setState({
			changeNameshow: true,
			changeNameTital: 'Change Name',
			commonModalId: id,
			newUserNameValue: name,

		})
	}

	commonmodelFooter = (type = '') => {
		if (type === 'archiveChat') {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.closeCommonModel()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>No </span>
					</button>
					<button
						type="button"
						onClick={(e) => this.updateActionModal("archiveChat", this.state.commonModalId)}
						className="btn linear-gradient yr-submitbtn btn-sm"
						disabled={this.state.commonmodelSaveButtonDisabled}
					>
						<span>Yes</span>
					</button>
				</div>
			)
		} else if (type === 'termscondition') {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.closeCommonModel()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>Close</span>
					</button>
				</div>
			)
		}
	}

	closeCommonModel = () => {
		this.setState({
			commonmodelshow: false,
			commonmodelBody: null,
			commonmodelFooter: null,
			commonModelTital: '',
			commonModalId: null,
			commonmodelSaveButtonDisabled: false
		})
	}

	updateActionModal = (type = '', modalid = null) => {
		if (type === 'archiveChat') {
			if (modalid) {
				this.setState({
					commonmodelSaveButtonDisabled: true,
					commonModelLoading: true,
				});
				api.liveChat.archiveSmsChat({ id: modalid })
					.then((res) => {
						let colorName = 'red';
						if (res.data.status) {
							colorName = 'green';
							this.setState({
								changeNameRes: [res.data.message, colorName]
							});
							//remove chat from listing
							this.removechatFromList(modalid);
							this.loadLiveChatDataFromServer(modalid);
							this.fethSmsListData();
						} else {
							this.setState({
								changeNameRes: [res.data.message, colorName],
							});
						}
						setTimeout(() => {
							this.setState({
								changeNameRes: '',
							});
						}, 5000);
					}).catch((err) => {
						console.log(err);
					}).finally(() => {
						this.setState({
							commonModelLoading: false,
							commonmodelSaveButtonDisabled: false,
							commonmodelshow: false,
							commonModelTital: '',
							commonModalId: null,
						});
					})
			}
		}
	}

	removechatFromList = (chatAnonymousId) => {
		// console.log('remove', this.state.chat, this.state.chatLeftList);
		if (chatAnonymousId) {
			let chatData = this.state.chat;
			let leftChatData = this.state.chatLeftList;
			let chatRemain = this.state.chatRemain;
			// console.log("before delete", chatData, leftChatData)
			const chatDataIndex = chatData.findIndex((chatDataItem) => chatDataItem.anonymous_user_id == chatAnonymousId);
			// console.log("chatDataIndex", chatDataIndex, chatAnonymousId);
			if (chatDataIndex > -1) {
				chatData.splice(chatDataIndex, 1);
			}
			const leftChatDataIndex = leftChatData.findIndex((leftChatDataItem) => leftChatDataItem.anonymous_user_id == chatAnonymousId);
			if (leftChatDataIndex > -1) {
				leftChatData.splice(leftChatDataIndex, 1);
			}
			// console.log("after delete", chatData, leftChatData);
			if (chatRemain.hasOwnProperty('chatAnonymousId')) {
				delete chatRemain[chatAnonymousId];
			}
			this.setState({
				chat: chatData,
				chatLeftList: leftChatData,
				chatRemain: chatRemain
			}, () => {
				if (this.state.chat.length === 0) {
					this.setState({
						displayTextArea: "none",
						blankBoxStyle: "block"
					})
				} else {
					this.openLiveChatBoxOnClickLeftRow(this.state.chat[0].anonymous_user_id, this.state.hippaOn)
				}
			});
		}
	}

	showMessageRes = (message, color) => {
		// console.log(message, color);
		return (
			<Message
				color={color}
				style={{
					display: "block",
					height: "44px"
				}}
			>
				{message}
			</Message>
		)
	}

	changeNameBody = () => {
		return (
			<div className="row">
				<div className="col-md-12">

					<div className="form-group">
						<input type="text"
							required
							// autoFocus 
							// defaultValue={this.state.newUserNameValue}
							value={this.state.newUserNameValue}
							className="form-control"
							// name="newUserNameValue" 
							onChange={(e) => this.handleChangeUserName(e)}
						/>

						{this.state.changeUserNameError && <small className="text-danger">{this.state.changeUserNameError}</small>}
					</div>
				</div>
			</div>
		)
	}

	changeNameFooter = () => {
		return (
			<div className="modal-footer">
				<button
					type="button"
					onClick={() => this.closechangeName()}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
				>
					<span>Close</span>
				</button>
				<button
					type="button"
					onClick={() => this.saveChangeUserName(this.state.commonModalId)}
					className="btn linear-gradient yr-submitbtn btn-sm"
					disabled={this.state.commonmodelSaveButtonDisabled}
				>
					<span>Save</span>
				</button>
			</div>
		)
	}

	closechangeName = () => {
		this.setState({
			changeNameshow: false
		})
	}

	onViewArchiveChat = (id) => {
		this.setState({archiveLoading : true});
		api.chatlist.viewDetails({auto_id : id})
		.then((res) => {
			if (res.status) {
				this.setState({
					archiveLoading : false,
					commonmodelshow: true,
					commonModalId: id,
					commonmodelBody: this.commonmodelBody('viewarchivechat', res.data.data),
					commonmodelFooter: this.commonmodelFooter('termscondition'),
					commonModelTital: 'Chat Details',
				});
			}else{
				this.setState({
					archiveLoading : false,
					changeNameRes: ["Something went wrong", 'red'],
				});
			}
		}).catch((err) => {
			this.setState({
				archiveLoading : false,
				changeNameRes: ["Something went wrong", 'red'],
			});
			console.log(err);
		})
	}

	setLiveChatTab = () => {
		this.setState({
			liveChatTab : 'web',
			displayTextArea: "none",
			blankBoxStyle: "block"
		}, () => {
			this.setChatArea('web')
		})
	}
	
	render() {
		const { activeIndex } = this.state
		return (
			<div
				className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
				id="liveChatPage"
			>
				{this.state.loading && <div class="ui loading form"></div>}
        {/* Modal */}
        {this.state.deleteMessageConfirmationDialog && 
        <ConfirmDialog 
        show={this.state.deleteMessageConfirmationDialog }
        toggleModal={(e)=>this.deleteSingleChat(e,this.state.chatIdToBeDeleted)}
        closeAction={this.closeModal}
        title="Confirm your action"
        message={"Do you really want to delete this chat?"}
        />
        }
				<div className="kt-content kt-grid__item kt-grid__item--fluid mh-custom-marg0">
					<div className="yr-body-p mh-custom-pad0">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12">
								<div className="kt-portlet mh-custom-marg0">
									<div className="kt-portlet__body py-0 mh-custom-pad0">
										<div className="row">
											<div className="col-lg-3 livechat-left yr-pd0-mob yr-pd0-lr">
												{/* <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
													role="tablist"
												>
													<li className="nav-item">
														<a
															className={`nav-link active`}
															href="#livechat"
															data-toggle="tab"
															role="tab"
															onClick={() => this.setLiveChatTab()}
														>
															Web chats
														</a>
													</li>
													<li className="nav-item">
														<a
															className={`nav-link`}
															href="#smschat"
															data-toggle="tab"
															role="tab"
															onClick={() => this.fethSmsListData()}
														>
															SMS text chats
														</a>
													</li>
												</ul>*/}
												<div className="tab-content">
													<div
														className={`tab-pane active`}
														id="livechat"
														role="tabpanel"
													>
														<LeftSideAccordion
															props={this.props}
															state={this.state}
															openLiveChatBoxOnClickLeftRow={
																this.openLiveChatBoxOnClickLeftRow
															}
                          showDeleteConfimationModal={this.showDeleteConfimationModal}
															openLeftAccordion={this.openLeftAccordion}
														/>
													</div>
													<div
														className={`tab-pane`}
														id="smschat"
														role="tabpanel"
													>
														{/* <LiveChatTab 
															smsTextContent={this.state.textMessage}
															handleSmsTextContent={this.handleSmsTextContent}
															key="livechattab2"
														/> */}
														<div className=''>
															<Segment>
																<Dimmer active={this.state.loadingSendText}>
																	<Loader indeterminate>Loading...</Loader>
																</Dimmer>
																<Message
																	color={this.state.changeNameRes[1]}
																	style={{
																		display: this.state.changeNameRes ? "block" : "none",
																		height: "44px"
																	}}
																>
																	{this.state.changeNameRes[0]}
																</Message>
																<div></div>
																<div className='ml-4'>
																	<Button
																		disable={false}
																		type="button"
																		handleClick={this.handleClick}
																	>
																		Send a text
																	</Button>
																	{this.state.trumpiaNumberNotFound && <div>
																		<p style={{ fontWeight: 600 }}>
																			Currently no phone number is configured. Please  <Link to="/marketing/listdomain" className="kt-menu__link ">click here</Link> to send a request to Admin.
																		</p>
																	</div>}
																</div>

																<div className=''>
																	<Accordion>
																		<Accordion.Title
																			active={activeIndex === 0}
																			index={0}
																			onClick={this.handleTitalClick}
																		>
																			<Icon name='angle right' />
																			Engaged Customers
																		</Accordion.Title>
																		<Accordion.Content active={activeIndex === 0}>
																			<Segment>
																				<EngageChat
																					props={this.props}
																					state={this.state}
																					openLiveChatBoxOnClickLeftRow={
																						this.openLiveChatBoxOnClickLeftRow
																					}
																					onChnageName={this.changeUserName}
																					onArchive={this.archiveChat}
																				/>
																			</Segment>
																		</Accordion.Content>
																		<Accordion.Title
																			active={activeIndex === 1}
																			index={1}
																			onClick={this.handleTitalClick}
																		>
																			<Icon name='angle right' />
																			Text with no reply
																		</Accordion.Title>
																		<Accordion.Content active={activeIndex === 1}>
																			<Segment>
																				<Dimmer active={this.state.listLoading}>
																					<Loader indeterminate>Loading...</Loader>
																				</Dimmer>
																				<ChatWithNoReply
																					onChnageName={this.changeUserName}
																					onArchive={this.archiveChat} allTextSendList={this.state.allTextSendList} />
																				{this.state.allTextSendView &&
																					<div style={{ display: 'flex', justifyContent: 'center' }}><a href="#" onClick={(e) => this.viewMorelist(e, 'textwithnoreply')} >View more</a></div>
																				}
																			</Segment>

																		</Accordion.Content>
																		<Accordion.Title
																			active={activeIndex === 2}
																			index={2}
																			onClick={this.handleTitalClick}
																		>
																			<Icon name='angle right' />
																			Archived Chat
																		</Accordion.Title>
																		<Accordion.Content active={activeIndex === 2}>
																			<Segment>
																				<Dimmer active={this.state.archiveLoading}>
																					<Loader indeterminate>Loading...</Loader>
																				</Dimmer>
																				<ArchiveChat
																					viewArchiveChat={this.onViewArchiveChat}
																					smsArchiveList={this.state.smsArchiveList} />
																				{this.state.smsArchiveView &&
																					<div style={{ display: 'flex', justifyContent: 'center' }}><a href="#" onClick={(e) => this.viewMorearchivelist(e, 'archivelist')} >View more</a></div>
																				}
																			</Segment>
																		</Accordion.Content>
																	</Accordion>
																</div>
															</Segment>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-9 right-side-chat yr-pd0-mob yr-pd0-lr">
												<div
													style={{ display: this.state.blankBoxStyle }}
													className="mh-blankBoxOnLiveChat"
												>
													<div className="yr-right-chatbox yr-rightbar-chatBox hideshowChat mh-noChatBar">
														<div className="yr-welcome-box">
															<span>
																<h1>Welcome {this.props.user.name}! </h1>

																<p style={{ fontWeight: 600 }}>
																There are currently no chats. Feel free to edit 
																your{" "}
																	<a onClick={() => this.showModel()} href="#">
																		quick response
																	</a>{" "}
																	or edit your{" "}
																	<a
																		onClick={() =>
																			this.openSoundNotificationSetting()
																		}
																		href="#"
																	>
																		sound notification settings
																	</a>
																</p>
															</span>
														</div>
													</div>
												</div>
												{this.renderWholeChatContainer()}
												<LiveChatBoxFooter
													socket={this.props.socket}
													sendJumpingMessage={this.sendJumpingMessage}
													sessionUser={this.props.user}
													state={this.state}
													closeConversation={this.state.closeConversation}
													toggleEmogiesBar={this.toggleEmogiesBar}
													sendAgent={this.sendAgent}
												/>
												<DialogBox
													addQuickText={this.addQuickText}
													deleteAllQuickText={this.deleteAllQuickText}
													show={this.state.show}
													toggleModal={this.toggleModal}
													title="Quick Text"
													width="700px"
													height="320px"
													modalBodyId={"quickText"}
												//message={this.state.message}
												>
													<OpenQuickText
														parentState={this.state}
														ref={(child) => {
															this.child = child;
														}}
														addQuickText={this.state.addQuickText}
														sessionUser={this.props.user}
														quickTextAddEditDelete={this.quickTextAddEditDelete}
													/>
												</DialogBox>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DialogBoxHipaaPin
					email={this.props.user.email}
					obj={this.state}
					modalClose={this.modalClose}
					onValidHipaaSubmit={this.onValidHipaaSubmit}
					openHipaaAfterRegister={this.openHipaaAfterRegister}
				/>
				<DialogBoxConfirmation
					obj={this.state}
					DialogBoxConfirmationClose={this.DialogBoxConfirmationClose}
					DialogBoxConfirmation={this.state.DialogBoxConfirmation}
					onDialogBoxConfirmationTransfer={this.onDialogBoxConfirmationTransfer}
					onDialogBoxConfirmationMarkAsClosed={this.onDialogBoxConfirmationMarkAsClosed}
					width="400px"
					height="200px"
				/>
				<DialogBoxSoundNotificationSetting
					obj={this.state}
					SoundNonificationSetting={this.SoundNonificationSetting}
					onValidSubmitSoundNotification={this.onValidSubmitSoundNotification}
					sessionUser={this.props.sessionUser}
					showSoundNotiDialog={this.state.showSoundNotiDialog}
					soundSettingModalClose={this.soundSettingModalClose}
				/>
				<Modal
					show={this.state.showModelSendText}
					footer={this.modalFooterSendText()}
					onclose={() => this.modalCloseSendText('sendform')}
					title={this.state.sendTextModalTital}
					body={this.modalBodySendText()}
					loading={this.state.sendTextModalLoading}
				/>
				<Modal
					show={this.state.showModelRecipient}
					footer={''}
					onclose={this.closeViewRecipientModel}
					title={this.state.viewRecipientModalTital}
					body={this.viewRecipientBody()}
					loading={this.state.viewRecipientModalLoading}
				/>

				<Modal
					show={this.state.commonmodelshow}
					footer={this.state.commonmodelFooter}
					onclose={this.closeCommonModel}
					title={this.state.commonModelTital}
					body={this.state.commonmodelBody}
					loading={this.state.commonModelLoading}
				/>

				<Modal
					show={this.state.changeNameshow}
					footer={this.changeNameFooter()}
					onclose={this.closechangeName}
					title={this.state.changeNameTital}
					body={this.changeNameBody()}
					loading={this.state.changeNameLoading}
				/>

			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.user.company ? state.user.company : null,
	};
};
export default connect(mapStateToProps, {
	toggleNavigation,
	setPageTitle,
	setMessage,
	toggleBreadcrum,

})(LiveChat);
// export default LiveChat;
