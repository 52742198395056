import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
import api from "../../../api";
const queryString = require("query-string");

class ChatStaus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selecteditem: this.props.selected_chat
        };
    }
    componentDidMount() {
        this.setState({ selecteditem: this.props.selected_chat })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auto_id !== this.props.auto_id) {
            this.setState({ selecteditem: this.props.selected_chat })
        }

    }

    changeStatus = (e) => {
        this.setState({ selecteditem: e.target.value })
        document.querySelector(".chatstatusloadingdiv").style.display = "block";
        api.chatlist.changeNotesStatus({ 'auto_id': this.props.auto_id,'status':e.target.value,'chatRef':this.props.chatRef}).then(data => {
        
            try {
                document.querySelector(".chatstatusloadingdiv").style.display = "none";
                document.querySelector(".chatstatusloadingdivsuccess").style.display = "block";
                document.querySelector(".chatstatusloadingdivsuccess").innerHTML = data.message;
                document.querySelector("#trans_chatstatus_"+this.props.auto_id).innerHTML = data.chatVal;
                
                setTimeout(
                    function () {
                        document.querySelector(".chatstatusloadingdivsuccess").style.display = "none";
                    }.bind(this),
                    3000
                );
      
            } catch (err) {
      
            }
          });



    }

    render() {
        console.log("update",this.state.selecteditem);

        return (
            <div className="kt-form__control w-100 align-items-center yr-time-ShowHide chatstatus">
            <p className="m-0" style={{ width: "50%" }}>Transcript Status</p>
            <span class="custom-arrow">
                <select style={{ width: "auto", height: "auto" }} className="form-control bootstrap-select arrow-color mat-style" value={this.state.selecteditem == null ? 0 : this.state.selecteditem} onChange={(e) => this.changeStatus(e)} >
                    {this.props.list.map((obj, index) => {
                        if (typeof obj === "object" && obj.submenu) {
                            return (
                                <optgroup label={obj.label} key={index}>
                                    {obj.submenu.map((submenuItem, subIndex) => (
                                        <option value={submenuItem.value} key={`${index}-${subIndex}`}>
                                            {submenuItem.label}
                                        </option>
                                    ))}
                                </optgroup>
                            );
                        } else if (typeof obj === "object" && obj.value !== undefined) {
                            return (
                                <option value={obj.value} key={index}>
                                    {obj.label}
                                </option>
                            );
                        } else {
                            return (
                                <option value={index} key={index}>
                                    {obj}
                                </option>
                            );
                        }
                    })}

                </select>
            </span>
        </div>
        );
    }
}
export default ChatStaus
