import React from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
// import DesignRules from './DesignRules';
import api from "../../../api";
import './Chat.css';

import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
const queryString = require("query-string");

class ChatFilter extends React.Component {
#oneMonthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
#today =moment().format('MM/DD/YYYY');
constructor(props) {
    super(props);
   
    this.state = {
        chatstatus: 'all',
        daterange: 'daterange',
        selectedtext:this.#oneMonthAgo +" - " + this.#today,
        rangepicker:true,
        csvdata:[],
        serach:'',
        filter:'',
        isOpen: false


    };
    }
    openModal= ()=> {
        this.setState({isOpen: true})
      }

chatStatusChange=(e)=>{
    this.setState({
        chatstatus:e.target.value
    })
    
        this.props.onSubmitFilter('chatstatus',e.target.value);
    
    
}

handleChange=(e)=>{
    console.log(e.target.value)
    this.setState({
        serach:e.target.value
    })
    
}
handleSubmit=(e)=>{
    e.preventDefault();
    console.log("---",this.state.serach);
    this.props.onSubmitFilter('q',this.state.serach);
}
chatTimeChange=(e)=>{
    this.setState({
        chatstatus:e.target.value
    })
    if(e.target.value=='daterange'){
        const elements = document.querySelectorAll(`.opensright`);
        if(elements.length>0){
            elements[0].remove();
           
        }
        this.setState({
            rangepicker:true,
            daterange:e.target.value
        },function(){
           // document.querySelector(".showrangepicker").click();
            document.getElementById('chatdatepicker').click();
        })
        
        
    }else{
        this.setState({rangepicker:false,daterange:e.target.value});
        this.props.onSubmitFilter('chatfrequency',e.target.value);
    }
    
}
notify = (msg) =>  toast(msg, {
    position: 'top-right',
    autoClose: 5000,
    type:'error',
  });
applyDateRange=(event, picker)=>{
   var end = moment(picker.endDate); 
       var now = moment(picker.startDate);
       var duration = end.diff(now,'days');
       if(duration<=365){
            this.setState({
                'selectedtext':picker.startDate.format('MM/DD/YYYY') +" - " + picker.endDate.format('MM/DD/YYYY')
            });
            let range={};
            range['chatfrequency']='daterange'
            range['startdaterange']=picker.startDate.format('MM/DD/YYYY')
            range['enddaterange']=picker.endDate.format('MM/DD/YYYY')
            this.props.onSubmitFilterDateReange(range);
        }else{
            this.notify("Select a date-range of 1 year or less.");
            document.getElementById('chatdatepicker').click();
    
        }        
}
handleClick = (e) => {
    this.datepicker.click();
  }



render() {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final=month+"/"+date+"/"+year;
   
    return (
        <div className="kt-portlet__body px-0 cvbb" style={{paddingTop:0}}>
        

<div className="kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10">

    <div className="col-xl-12 order-2 order-xl-1">
        <div className="row">
            <div className="col-xl-2 col-lg-2">
                <div className="row align-items-center">
                    <div className="tbl-header-watch kt-margin-b-20-tablet-and-mobile">
                        <a href="#" onClick={this.openModal} className="btn linear-gradient zy-primary-btn">Watch this page tutorial</a>
                    </div>
                </div>
            </div>
            <div className="col-xl-10  col-lg-10">
                <div className="row align-items-center" style={{float:"right",marginRight:"20px"}}>
                 <form id="searchchat" method="post" onSubmit={(e)=>this.handleSubmit(e)}> 
                <div >	
                <div className="kt-input-icon kt-input-icon--right header-search kt-input-icon--right">
                    <input type="text" className="form-control" placeholder="Search..." id="generalSearch" name="q" onChange={(e)=>this.handleChange(e)}/>
                   
                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                    <span><i className="la la-search"></i></span>
                    </span>
                
                </div>
                </div>
                </form>
                  
                    <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                        <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                            <span class="custom-arrow">
                                <select className="form-control bootstrap-select arrow-color mat-style" name="chatstatus" id="kt_form_status" onChange={(e)=>this.chatStatusChange(e)}>
                                    <option value="all">All Chats</option>
                                    <option value="completed">Completed Chats</option>
                                    <option value="incompleted">Incompleted Chats</option>
                                    <option value="liveC">Live Chats</option>
                                </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                        <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                            <span class="custom-arrow">
                                <select className="form-control bootstrap-select arrow-color mat-style yr-time-ShowHide" value={this.state.daterange} name="chatfrequency" id="kt_form_type" onChange={(e)=>this.chatTimeChange(e)}>
                                    
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="pastsevendays">Past 7 days</option>
                                    <option value="pastmonth">Past Month</option>
                                    <option value="pastyear">Past Year</option>
                                    {/* <option value="alltime">All Time</option> */}
                                    <option value="daterange">Date Range</option> 
                                </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="kt-margin-b-20-tablet-and-mobile">
                        <button type="button" className="btn linear-gradient fix-btn-size yr-submitbtn calllist-btn fix-btn-size aichatbtn" disabled={this.props.totalCount==0 ? true:false} onClick={()=>this.props.dowloadcsv()}>
                            Export CSV</button>
                     </div>
                  </div>
            </div>
        </div>

    </div>
    {this.state.rangepicker &&
    <div className="col-xl-12 order-2 order-xl-1">
        <div className="tbl-header-opt-date kt-margin-b-20-tablet-and-mobile yr-d-float-right">
            <div className="kt-form__group kt-form__group--inline">
                <div className="kt-form__label">
                    <label></label>
                </div>

                <DateRangePicker
                    initialSettings={{ startDate: this.#oneMonthAgo,endDate:this.#today }}
                    onApply={(event, picker)=>this.applyDateRange(event, picker)}
                >
                    <div className="showrangepicker">
                      
                   <div className="form-control arrow-color mat-style" id="chatdatepicker" style={{cursor:"pointer"}}> {this.state.selectedtext} </div> 
                   </div>
                </DateRangePicker>
                {/* <div className="kt-form__control">
                   
                    <select className="form-control bootstrap-select arrow-color mat-style" id="kt_form_type">
                        <option value="">05/04/2020 - 06/05/2020</option>
                        <option value="1">05/02/2020 - 06/02/2020</option>
                    </select>
                </div> */}
            </div>
        </div>
    </div>
}
{this.props.requestedFiles.length>0  && <div className="downloaddiv" style={{marginTop:'5px',float:'right',marginRight:this.state.rangepicker ? '360px':'674px'}}><a target='_blank' href={this.props.requestedFiles[0].file_path} title="download chat csv file">Download Chat Data &nbsp;<i style={{color:"#087ab6 !important"}} className="fa fa-download ffaa"></i></a></div>}
</div>

<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='l7IC0BTSVKM' onClose={() => this.setState({isOpen: false})} />

<ToastContainer />
</div>

);
    }
}
export default ChatFilter